import { AxiosResponse } from "axios";

import axiosInstance from "../../../utils/axios";
import { verifyData, verifyDataList, verifyResponse } from "../../../utils/verify-response";
import Address, { AddressForm } from "./interfaces";

export async function getAddress(fk_id_address: number): Promise<Address | undefined> {
  return axiosInstance.get('/address/' + fk_id_address).then(response => {
    return verifyData(response);
  }).catch(error => {
    return verifyData(error);
  });
}

export async function patchAddress(address: Address): Promise<AxiosResponse> {
  return axiosInstance.patch('/address', address).then(response => {
    return verifyResponse(response);
  }).catch(error => {
    return verifyResponse(error);
  });
}