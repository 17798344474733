import { ErrorMessage, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { statusCode } from "../../../beans/enumerators";
import { messagesAction, messagesForm } from "../../../beans/messages";
import ErrorMessageCustom from "../../../components/forms/ErrorMessageCustom";
import { baseURL } from "../../../utils/axios";
import Yup from "../../error/Yup";
import Investment, { InvestmentForm } from "../interfaces/investments";
import { getInvestment, postInvestment, updateInvestment } from "../services/investments";

type FormImagesProps = {
  investmentSelected: Investment;
  setInvestmentSelected: Function;
};

const FormImages: React.FC<FormImagesProps> = ({
  investmentSelected,
  setInvestmentSelected,
}) => {
  const [sent, setSent] = useState(false);

  const [initialValues, setInitialValues] = useState<InvestmentForm>({
    submit: false,
  });

  useEffect(() => {
    if (investmentSelected) {
      setInitialValues({
        submit: false,
        ...investmentSelected,
      });
      setExistingImages(investmentSelected.investment_image);
    }
  }, [investmentSelected]);

  const [images, setImages] = useState<File[]>([]);
  const [existingImages, setExistingImages] = useState<string[]>();

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (!fileList) return;
    const newImages = Array.from(fileList).slice(0, 5);
    setImages(newImages);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    const newImages = Array.from(files).slice(0, 5);
    setImages(newImages);
  };

  const handleImageDelete = (index: number) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
  };

  const handleExistingImageDelete = (index: number) => {
    if (existingImages) {
      const newImages = [...existingImages];
      newImages.splice(index, 1);
      setExistingImages(newImages);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        let newValues: any = { ...values };
        delete newValues.submit;
        delete newValues.investment.totalInvestors;
        delete newValues.investment.totalInvested;
        delete newValues.address.id_address;
        delete newValues.address.fk_id_city;
        delete newValues.investment.code;
        var investmentData = new FormData();
        newValues.investment_image = existingImages;
        images.forEach((image) => {
          investmentData.append("investment_image", image);
          newValues.investment_image = [...newValues.investment_image, "true"];
        });
        investmentData.append("data", JSON.stringify(newValues));

        let response = await updateInvestment(investmentData);
        if (response.status === statusCode.ok) {
          if (values.investment?.uuid_investment)
            getInvestment(values.investment.uuid_investment).then((response) =>
              setInvestmentSelected(response)
            );
          setSent(true);
          setTimeout(() => {
            setSent(false);
          }, 5000);
          setImages([]);
        } else {
          const message: string = response.statusText || messagesForm.error;
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({ errors, handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <div>
            <div
              style={{ border: "2px dashed gray", padding: "1rem" }}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <p>Arraste e solte até 5 imagens aqui</p>
              <input
                type="file"
                accept="image/*"
                multiple
                onChange={handleFileSelect}
                style={{ display: "none" }}
                id="image-input"
              />
              <p>
                <label htmlFor="image-input">
                  Clique para selecionar imagens
                </label>
              </p>
              <div>
                {existingImages?.map((image, index) => (
                  <div
                    key={`existing-image-${index}`}
                    style={{ display: "inline-block", marginRight: "1rem" }}
                    className="position-relative"
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "0",
                        right: "0",
                        padding: "0 0.5rem",
                        fontWeight: "600",
                        cursor: "pointer",
                        backgroundColor: "white",
                      }}
                      onClick={() => handleExistingImageDelete(index)}
                    >
                      X
                    </div>
                    <img
                      src={baseURL + "/uploads/investment/" + image}
                      style={{
                        width: "150px",
                        height: "150px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                ))}
                {images.map((image, index) => (
                  <div
                    key={`new-image-${index}`}
                    style={{ display: "inline-block", marginRight: "1rem" }}
                    className="position-relative"
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "0",
                        right: "0",
                        padding: "0 0.5rem",
                        fontWeight: "600",
                        cursor: "pointer",
                        backgroundColor: "white",
                      }}
                      onClick={() => handleImageDelete(index)}
                    >
                      X
                    </div>
                    <img
                      src={URL.createObjectURL(image)}
                      style={{
                        width: "150px",
                        height: "150px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>

          {errors.submit && (
            <Alert className="mt-4" variant="danger">
              <div className="alert-message">{errors.submit}</div>
            </Alert>
          )}

          {sent && (
            <Alert className="mt-4" variant="success">
              <div className="alert-message">{messagesAction.success}</div>
            </Alert>
          )}

          <div className="mt-4">
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={isSubmitting}
            >
              {messagesAction.save}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FormImages;
