import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useRoutes } from "react-router-dom";

import { SidebarProvider } from "./components/sidebar/contexts/SidebarContext";
import routes from "./routes";
import { DashboardProvider } from "./utils/contexts/DashboardContext";
import { TokenProvider } from "./utils/contexts/TokenContext";
import { SocketProvider } from "./utils/contexts/SocketContext";

const App = () => {
  const content = useRoutes(routes);

  return (
    <HelmetProvider>
      <Helmet
        titleTemplate={"%s | " + process.env.REACT_APP_WEBSITE_NAME}
        defaultTitle={process.env.REACT_APP_WEBSITE_NAME}
      />

      {
        <SocketProvider>
          <DashboardProvider>
            <TokenProvider>
              <SidebarProvider>{content}</SidebarProvider>
            </TokenProvider>
          </DashboardProvider>
        </SocketProvider>
      }

    </HelmetProvider>
  );
};

export default App;
