import React from "react";

import { Col, Row, Collapse, Card } from "react-bootstrap";

import { account_type } from "../../../beans/enumerators";

interface FilterProps {
  verifyFilters: Function;
  openFilters: boolean;
  theFilters: any;
}

const Filter = ({ verifyFilters, openFilters, theFilters }: FilterProps) => {
  return (
    <Collapse in={openFilters} className="mq-filter p-3">
      <Card>
        <Row>
          <Col sm="auto">
            <label className="form-label">Tipo de usuário</label>
            <select
              defaultValue={theFilters.account_type}
              className="form-control form-select input-filter"
              onChange={(e) =>
                verifyFilters("account_type", e.target.value, true)
              }
            >
              <option value="">Todos</option>
              <option value={account_type.admin}>Administrador</option>
              <option value={account_type.client}>Cliente</option>
            </select>
          </Col>
        </Row>
      </Card>
    </Collapse>
  );
};

export default Filter;
