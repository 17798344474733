import { ErrorMessage, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import CurrencyInput from "react-currency-input-field";

import { statusCode } from "../../../beans/enumerators";
import { messagesAction, messagesForm } from "../../../beans/messages";
import ErrorMessageCustom from "../../../components/forms/ErrorMessageCustom";
import Yup from "../../error/Yup";
import Investment, { LiquidationForm } from "../interfaces/investments";
import ModalLiquidationLogin from "./ModalLiquidationLogin";

interface ModalConfirmLiquidation {
    show: boolean;
    setModalShow: React.Dispatch<React.SetStateAction<boolean>>;
    investmentSelected: Investment;
}

const ModalConfirmLiquidation = ({
    setModalShow,
    show,
    investmentSelected,
    ...rest
}: ModalProps | ModalConfirmLiquidation) => {
    const initialValues: LiquidationForm = {
        rentability: '',
        submit: false
    }
    const [valuesForm, setValuesForm] = useState<LiquidationForm>(initialValues);
    const [showLoginModal, setShowLoginModal] = useState<boolean>(false);

    const formikSubmit = async (
        values: LiquidationForm,
        {
            setErrors,
            setStatus,
            setSubmitting,
        }: FormikHelpers<LiquidationForm>
    ) => {
        let { submit, rentability } = values;

        handleClose();
        setValuesForm({
            submit: true,
            rentability
        })
        setShowLoginModal(true);
    };

    function handleClose() {
        setModalShow(false);
    }

    return (
        <>
            <Modal
                show={show}
                onHide={() => {
                    handleClose();
                }}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validationSchema={Yup.object({
                        rentability: Yup.number().nullable()
                    })}
                    validateOnBlur={true}
                    onSubmit={formikSubmit}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        touched,
                        isSubmitting,
                        values,
                        status,
                        setFieldValue,
                    }) => (
                        <>
                            <Form onSubmit={handleSubmit}>
                                <Row className="justify-content-between">
                                    <Col>
                                        <Form.Group className="m-3">
                                            <h3>Info.</h3>
                                            <p>A rentabilidade projetada deste investimento é de {investmentSelected?.investment?.rentability}%, caso queira informar uma nova rentabilidade, informe no campo
                                                abaixo.</p>
                                        </Form.Group>

                                    </Col>
                                    <Col xs="12">
                                        <Form.Group className="mb-3 p-3">
                                            <Form.Label>Rentabilidade real</Form.Label>
                                            <Form.Control
                                                size="lg"
                                                type="rentability"
                                                name="rentability"
                                                placeholder="0"
                                                value={values.rentability}
                                                isInvalid={Boolean(touched.rentability && errors.rentability)}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                            />
                                            <ErrorMessage name="value" render={ErrorMessageCustom} />
                                        </Form.Group>

                                        {errors.submit && (
                                            <Alert className="mt-4" variant="danger">
                                                <div className="alert-message">{errors.submit}</div>
                                            </Alert>
                                        )}
                                    </Col>
                                    <Col>

                                        <div className="m-3">
                                            <Button
                                                type="submit"
                                                variant="primary"
                                                size="lg"
                                                disabled={isSubmitting}
                                            >
                                                Confirmar
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </>
                    )}
                </Formik>

            </Modal>
            <ModalLiquidationLogin
                show={showLoginModal}
                setModalShow={setShowLoginModal}
                rentability={valuesForm.rentability}
                uuid_investment={investmentSelected.investment.uuid_investment}
                setValuesForm={setValuesForm}
            />
        </>

    );
};

export default ModalConfirmLiquidation;
