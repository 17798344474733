import React from "react";

import { situation_type } from "../../../beans/enumerators";
import { ObjectTransaction } from "../interfaces/transaction";

interface TdSituationProps {
  item: ObjectTransaction;
}

const TdSituation: React.FC<TdSituationProps> = ({ item }) => (
  <React.Fragment>
    <td
      className={
        "text-center " +
        ((item.situation == situation_type.approved || item.situation == situation_type.payed && "text-success") ||
          ((item.situation == situation_type.canceled ||
            item.situation == situation_type.canceledByUser) &&
            "text-danger") ||
          (item.situation == situation_type.analysis && "text-warning"))
      }
    >
      {
        (situation_type.analysis == item.situation && "Em análise") ||
        (situation_type.approved == item.situation && "Aprovado") ||
        (situation_type.canceled == item.situation && "Cancelado") ||
        (situation_type.canceledByUser == item.situation && "Cancelado pelo usuário") ||
        (situation_type.invested == item.situation && "Investido") ||
        (situation_type.payed == item.situation && "Pagamento de investimento")
      }
    </td>
  </React.Fragment>
);

export default TdSituation;
