export enum account_type {
  admin = 0,
  client = 1,
};

export enum situation_type {
  "approved" = 0, // quer dizer que o deposótio foi confirmado e somado no valor total da carteira do user
  "analysis" = 1, // Quer dizer que tem um valor aguardando confirmação de depósito e que por hora esse valor não soma e nem diminui da carteira do user
  "canceled" = 2, // Quer dizer que o valor foi cancelado, que então não é adicionado na carteira do user
  "canceledByUser" = 3, // Transação cancelado pelo usuário, ou seja, o valor foi depositado, mas foi cancelado pelo user
  "invested" = 4, //  Dinheiro que já foi compensado e agora o usuário investou um dinheiro, esse dinheiro fica com a situação invested
  "payed" = 5 //Apenas quando o investimento foiu liquidado 
}


export enum transaction_type {
  out = 0, //Saída
  in = 1 //Entrada
}

export enum statusDefault {
  liquidado = 5
}

export enum itens_per_page {
  default = 12
};


export enum statusCode {
  ok = 200,
  created = 201,
  notUpdated = 202,
  notOk = 400,
  notFound = 404,
  crashError = 500
}