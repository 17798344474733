import React, { useContext, useEffect } from "react";
import { PropsWithChildren } from "react";
import styled from "styled-components";

import { DashboardContext } from "../../../utils/contexts/DashboardContext";
import useToken from "../../../utils/hooks/useToken";

const Bound = styled.div`
  width: auto;
  display: inline-block;
  padding: 10px;
  border-radius: 10px;
  font-size: 1.15em;
  color: black;
`;

const MoneyRegion = styled.h3`
  font-size: 1.5em;
  color: #004295;
`;

const WalletBalance: React.FC<PropsWithChildren> = () => {
  let useDashboard = useContext(DashboardContext);

  useEffect(() => {
    useDashboard.updateStatistics();
  }, []);
  const statistics = useDashboard.statistics[3];

  return (
    <Bound>
      Saldo disponível em carteira{useToken().isAdmin && "s"}
      <MoneyRegion>{statistics ? statistics.data : "R$ 0,00"}</MoneyRegion>
    </Bound>
  );
};

export default WalletBalance;
