import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

import { itens_per_page } from "../../beans/enumerators";
import Header from "../../components/lists/Header";
import PaginationCustom from "../../components/lists/Pagination";
import Filter from "./components/Filter";
import InvestmentCard from "./components/InvestmentCard";
import Investment from "./interfaces/investments";
import { getInvestmentAll } from "./services/investments";

const ListInvestments = () => {
  const [nList, setNList] = useState<number>(0);
  const [list, setList] = useState<Array<Investment>>([]);
  const [pagination, setPagination] = useState<number>(0);
  const [filters, setFilters] = useState<any>({
    investment: "",
    pagination: "1",
  });

  function verifyFilters(key: string, newValue: string) {
    let newFilters = filters;
    newFilters[key] = newValue;
    if (key !== "pagination") newFilters["pagination"] = "1";
    setFilters(newFilters);
    screenUpdate();
  }

  function screenUpdate() {
    getInvestmentAll(filters).then((response) => {
      setList(response.list);
      setNList(response.total);
      response.total > 0
        ? setPagination(Math.ceil(response.total / itens_per_page.default))
        : setPagination(0);
    });
  }
  useEffect(() => {
    screenUpdate();
  }, []);

  const [openFilters, setOpenFilters] = useState(false);

  return (
    <React.Fragment>
      <Helmet title="Investimentos" />
      <Container fluid className="p-0">
        <Header
          title="Investimentos"
          verifyFilters={verifyFilters}
          filterName="investment"
          openFilters={openFilters}
          setOpenFilters={setOpenFilters}
        />
        <Filter
          openFilters={openFilters}
          verifyFilters={verifyFilters}
          theFilters={filters}
        />

        <div className="mt-4">{nList} registros</div>

        <Row>
          {!!list &&
            list.map((item) => (
              <InvestmentCard item={item} screenUpdate={screenUpdate} />
            ))}
        </Row>

        <PaginationCustom
          pagination={pagination}
          filter={filters.pagination}
          verifyFilters={verifyFilters}
        />
      </Container>
    </React.Fragment>
  );
};

export default ListInvestments;
