import React from "react";
import { PropsWithChildren } from "react";
import { Table } from "react-bootstrap";
import { TableCellProps } from "react-table";
import styled from "styled-components";

const ScrollableDiv = styled.div`
  max-height: 600px;
  overflow-y: auto;
`;


const StyledTable = styled(Table)`
  width: 100%;
  table-layout: fixed;

  tbody {
    display: block;
    height: 100%;
  }

  thead,
  tfoot,
  tbody tr {
    overflow-y: hidden;
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #fff;
    height: 30px; /* Defina uma altura fixa para o thead */
  }

  tfoot {
    position: sticky;
    bottom: 0;
    z-index: 1;
    background-color: #fff;
    height: 30px; /* Defina uma altura fixa para o thead */
  }
`;


interface TablePropsInterno {
  head: JSX.Element[];
  body: JSX.Element[];
  bottom: JSX.Element[];
}
const TableScroll = ({ head, body, bottom }: TablePropsInterno) => (
  <>

    <StyledTable responsive striped bordered hover size="sm">
      <ScrollableDiv>
        <thead>
          {head}
        </thead>
        <tbody>
          {body}
        </tbody>
        <tfoot>
          {bottom}
        </tfoot>
      </ScrollableDiv>
    </StyledTable>
  </>

);

export default TableScroll;
