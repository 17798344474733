import moment from 'moment';

require('moment/locale/pt-br'); 

export function getAllMonths(): {
    monthName: string;
    month: number;
}[] {
    const months = [];
    for (let i = 0; i < 12; i++) {
        const monthName = moment().month(i).locale('pt-br').format('MMMM');
        months.push({ monthName: monthName, month: i });
    }
    return months
}