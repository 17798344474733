import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from "react";

const SidebarFooter = () => {
  return (
    <div className="sidebar-cta">
      {<div className="sidebar-cta-content">
        <div className="d-grid">
          <a
            href="https://wa.me/5599981355310"

            target="_blank"
            rel="noreferrer"
          >
            <h5>
              <FontAwesomeIcon icon={faWhatsapp} size="2x" color="#25D366" />
              <strong className="d-inline-block mx-2 mb-2">Whatsapp</strong>
            </h5>
          </a>
        </div>

        <div className="mb-3 text-sm">
          Em caso de dúvidas, entre em contato com um de nossos especialistas.
        </div>



      </div>}
    </div >
  );
};

export default SidebarFooter;
