import axiosInstance from "../../../utils/axios";
import { verifyDataList, verifyData } from "../../../utils/verify-response";

import MaritalStatus from "../interfaces/marital-status";

export async function getMaritalStatusAll(): Promise<Array<MaritalStatus>> {
  return axiosInstance.get('/user/marital_status/').then(response => {
    return verifyDataList(response);
  }).catch(error => {
    return verifyDataList(error);
  });
}

export async function getMaritalStatus(id: number): Promise<MaritalStatus | undefined> {
  return axiosInstance.get('/user/marital_status/' + id).then(response => {
    return verifyData(response);
  }).catch(error => {
    return verifyData(error);
  });
}