import { AxiosResponse } from 'axios';

import axiosInstance from "../../../utils/axios";
import { verifyDataPagination, verifyResponse } from "../../../utils/verify-response";
import { HandleTransaction, ListTransaction } from '../interfaces/transaction';
import { TransactionDeposit } from '../interfaces/TransactionForm';

export async function getAllTransactions(filters: any): Promise<ListTransaction> {
    return axiosInstance.get('/user/transaction/getTransactions?' + new URLSearchParams(filters).toString()).then(response => {
        return verifyDataPagination(response);
    }).catch(error => {
        return verifyDataPagination(error);
    });
}

export async function handleTransaction(transactionData: HandleTransaction): Promise<AxiosResponse> {
    return axiosInstance.put('/user/transaction/changeStatus/', transactionData).then(response => {
        return verifyResponse(response);
    }).catch(error => {
        return verifyResponse(error);
    });
}

export async function depositTransaction(transactionData: TransactionDeposit): Promise<AxiosResponse> {
    return axiosInstance.post('/user/transaction/newTransaction', transactionData).then(response => {
        return verifyResponse(response);
    }).catch(error => {
        return verifyResponse(error);
    });
}