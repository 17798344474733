import React, { useEffect, useState } from "react";
import { Card, Container, Table } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

import { Status } from "./interfaces";
import { getStatusAll } from "./services";
import Header from "../../../components/lists/Header";

const ListStatus = () => {
  const [listStatus, setListStatus] = useState<Array<Status>>([]);

  function screenUpdate() {
    getStatusAll().then((response) => {
      if (response) {
        setListStatus(response);
      }
    });
  }

  useEffect(() => {
    screenUpdate();
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Gerenciar Status" />
      <Container fluid className="p-0">
        <Header title="Status" />

        <Card className="flex-fill w-100 mt-3">
          <Table striped className="my-0">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Cor</th>
                <th>Aberto para investimento?</th>
              </tr>
            </thead>
            <tbody>
              {!!listStatus &&
                listStatus.map((item) => (
                  <tr key={item.id_status}>
                    <td>{item.status}</td>
                    <td>
                      <span
                        style={{
                          backgroundColor: item.color,
                          border: "2px solid rgba(0,0,0,0.4) ",
                        }}
                        className="rounded-circle d-inline-block p-2"
                      ></span>
                    </td>
                    <td>{item.is_open ? "Sim" : "Não"}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default ListStatus;
