import {
  faBan,
  faCheckCircle,
  faPen,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Dropdown,
  ListGroup,
  ProgressBar,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import { statusCode } from "../../../beans/enumerators";
import { messagesAction } from "../../../beans/messages";
import { baseURL } from "../../../utils/axios";
import { formatMoney, formatNumber } from "../../../utils/format";
import useToken from "../../../utils/hooks/useToken";
import Investment, { InvestmentDetail } from "../interfaces/investments";
import {
  changeStatusInvestment,
  deleteInvestment,
} from "../services/investments";
import BtnDropdown from "../../../components/lists/BtnDropdown";

interface CardProps {
  item: Investment;
  screenUpdate: Function;
}

const InvestmentCard = ({ item, screenUpdate }: CardProps) => {
  const { isAdmin } = useToken();

  function handleDelete() {
    let uuid = item.investment?.uuid_investment;
    if (uuid) {
      deleteInvestment(uuid).then((response) => {
        if (response.status !== statusCode.ok) {
          alert(response.statusText);
        } else {
          screenUpdate();
          let v = document.getElementsByTagName("h1")[0].click;
        }
      });
    }
  }
  function handleStatus() {
    var investmentData: InvestmentDetail = {
      uuid_investment: item.investment?.uuid_investment,
    };
    if (item?.investment) investmentData.active = !item.investment.active;
    changeStatusInvestment(investmentData).then((response) => {
      if (response.status !== statusCode.ok) {
        alert(response.statusText);
      } else {
        screenUpdate();
        let v = document.getElementsByTagName("h1")[0].click;
      }
    });
  }

  return (
    <Col
      md="6"
      lg="3"
      className={item.investment?.active ? "" : "card-inactive"}
    >
      <Card className="mb-0 mt-3">
        {item.investment_image && item.investment_image.length > 0 && (
          <div className="img-cover-content">
            <Card.Img
              src={baseURL + "/uploads/investment/" + item.investment_image[0]}
              alt={item.investment?.investment}
              className="absolute-top w-100 h-100 img-cover"
            />
          </div>
        )}

        <Card.Header className="pb-0 pt-4">
          <span>Cód. {item.investment?.code}</span>
          <Card.Title className="mb-0">
            {item.investment?.investment}
          </Card.Title>
          <Badge
            className="mt-2"
            bg="none"
            style={{ backgroundColor: item.status?.color }}
          >
            {item.status?.status}
          </Badge>
        </Card.Header>

        <Card.Body>
          <ListGroup variant="flush">
            <ListGroup.Item className="px-0 pt-0 pb-4">
              <p className="text-muted mb-0">
                Investimento captado<span className="float-end">Objetivo</span>
              </p>
              <p className="mb-2 fw-bold">
                R$ {formatMoney(item.investment?.totalInvested)}
                <span className="float-end">
                  R$ {formatMoney(item.investment?.value_goal)}
                </span>
              </p>
              <ProgressBar
                className="progress-sm"
                now={
                  (Number(item.investment?.totalInvested) * 100) /
                  Number(item.investment?.value_goal)
                }
              />
            </ListGroup.Item>
            <ListGroup.Item className="px-0">
              <span className="text-muted">Rentabilidade Projetada</span>
              <span className="float-end fw-bold">
                {formatNumber(item.investment?.rentability)}%
              </span>
            </ListGroup.Item>
            <ListGroup.Item className="px-0">
              <span className="text-muted">Investidores</span>
              <span className="float-end fw-bold">
                {item.investment?.totalInvestors}
              </span>
            </ListGroup.Item>
            <ListGroup.Item className="px-0">
              <span className="text-muted">Investimento Mínimo</span>
              <span className="float-end fw-bold">
                R$ {formatMoney(item.investment?.minimal_investment)}
              </span>
            </ListGroup.Item>
            <ListGroup.Item className="px-0">
              <span className="text-muted">Resgate</span>
              <span className="float-end fw-bold">
                {moment(item.investment?.redemption).format("DD/MM/YYYY")}
              </span>
            </ListGroup.Item>
          </ListGroup>
        </Card.Body>

        <Card.Footer className="text-center pt-0 mq-actions">
          {isAdmin ? (
            <>
              <Link
                to={
                  "/gerenciar-investimentos/" +
                  item.investment?.uuid_investment +
                  "/"
                }
              >
                <Button variant="primary" className="me-1">
                  <FontAwesomeIcon icon={faPen} /> {messagesAction.edit}
                </Button>
              </Link>
              {item.investment?.active ? (
                <BtnDropdown
                  btn={messagesAction.inactive}
                  icon={faBan}
                  title={messagesAction.inactive}
                  msg="O investimento NÃO será mais visível para os clientes."
                  handleAction={handleStatus}
                  variant_color="warning"
                />
              ) : (
                <>
                  <BtnDropdown
                    btn={messagesAction.active}
                    icon={faCheckCircle}
                    title={messagesAction.active}
                    msg="O investimento será mais visível para os clientes."
                    handleAction={handleStatus}
                    variant_color="success"
                  />
                  <BtnDropdown
                    btn={messagesAction.delete}
                    icon={faTimesCircle}
                    title={messagesAction.delete}
                    msg="Se o investimento possuir dependências não será excluído."
                    handleAction={handleDelete}
                  />
                </>
              )}
            </>
          ) : (
            <Link
              to={"/investimentos/" + item.investment?.uuid_investment + "/"}
            >
              <Button
                variant={item.status?.is_open ? "yellow" : "primary"}
                size="lg"
              >
                {item.status?.is_open ? "Investir" : "Visualizar"}
              </Button>
            </Link>
          )}
        </Card.Footer>
      </Card>
    </Col>
  );
};

export default InvestmentCard;
