import { AxiosResponse } from "axios";

import axiosInstance from "../../../utils/axios";
import { verifyData, verifyDataList, verifyResponse } from "../../../utils/verify-response";
import { Status } from "./interfaces";

export async function getStatus(id: number): Promise<Status | undefined> {
    return await axiosInstance.get('/investment/status/' + id).then(response => {
        return verifyData(response);
    }).catch(error => {
        return verifyData(error);
    });
}
export async function getStatusAll(): Promise<Array<Status>> {
    return await axiosInstance.get('/investment/status/').then(response => {
        return verifyDataList(response);
    }).catch(error => {
        return verifyDataList(error);
    });
}

export async function patchStatus(status: Status): Promise<AxiosResponse> {
    return axiosInstance.patch('/investment/status/', status).then(response => {
        return verifyResponse(response);
    }).catch(error => {
        return verifyResponse(error);
    });
}

export async function deleteStatus(id: number): Promise<AxiosResponse> {
    return axiosInstance.delete('/investment/status/' + id).then(response => {
        return verifyResponse(response);
    }).catch(error => {
        return verifyResponse(error);
    });
}