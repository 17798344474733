import { Formik, FormikHelpers } from "formik";
import react, { useEffect, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";

import { statusCode } from "../../../beans/enumerators";
import { messagesAction, messagesForm } from "../../../beans/messages";
import InputsAddress from "../../../components/forms/InputsAddress";
import Yup from "../../error/Yup";
import { UserFormProps } from "../components/FormPersonal";
import { AddressForm } from "./interfaces";
import { getAddress, patchAddress } from "./services";

const UserFormAddress = ({ userSelected, setUserSelected }: UserFormProps) => {
  const [idTimeOut, setIdTimeOut] = useState<any>();
  const [initialValues, setInitialValues] = useState<AddressForm>({
    address: {
      addressNumber: "",
      complement: "",
      line_1: "",
      line_2: "",
      neighborhood: "",
      postalCode: "",
    },
    city: "",
    country: "Brasil",
    state: "",
    uuid_user: "",
    submit: false,
  });

  useEffect(() => {
    if (userSelected.user?.fk_id_address) {
      getAddress(userSelected.user?.fk_id_address).then((response) => {
        if (response) {
          setInitialValues({ ...response, submit: false });
        }
      });
    }
  }, [userSelected]);

  const formikSubmit = async (
    values: AddressForm,
    { setErrors, setStatus, setSubmitting }: FormikHelpers<AddressForm>
  ) => {
    let { submit, ...dataAddress } = values;
    dataAddress = { ...dataAddress, uuid_user: userSelected.user?.uuid_user };
    let response = await patchAddress(dataAddress);
    if (
      response.status === statusCode.created ||
      response.status === statusCode.ok
    ) {
      setStatus(true);
      clearTimeout(idTimeOut);
      setIdTimeOut(
        setTimeout(() => {
          setStatus(false);
        }, 5000)
      );
    } else {
      const message: string = response.statusText || messagesForm.error;
      setStatus({ success: false });
      setErrors({ submit: message });
      setSubmitting(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={formikSubmit}
        validationSchema={Yup.object().shape({
          address: Yup.object().shape({
            postalCode: Yup.string()
              .required(messagesForm.required)
              .min(8)
              .max(8),
            line_1: Yup.string().max(100),
            line_2: Yup.string().max(100),
            complement: Yup.string().max(100),
            addressNumber: Yup.string().required(messagesForm.required).max(10),
          }),
        })}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          touched,
          values,
          status,
          setErrors,
        }) => (
          <>
            <Form onSubmit={handleSubmit}>
              <InputsAddress
                errors={errors}
                setFieldValue={setFieldValue}
                touched={touched}
                handleBlur={handleBlur}
                handleChange={handleChange}
                values={values}
              />

              {errors.submit && (
                <Alert className="mt-4" variant="danger">
                  <div className="alert-message">{errors.submit}</div>
                </Alert>
              )}

              {status == true && (
                <Alert className="mt-4" variant="success">
                  <div className="alert-message">{messagesAction.success}</div>
                </Alert>
              )}

              <div className="mt-4">
                <Button
                  type="submit"
                  variant="primary"
                  size="lg"
                  disabled={isSubmitting}
                >
                  {messagesAction.save}
                </Button>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

export default UserFormAddress;
