import { SidebarItemsType } from "./interfaces/sidebar";

import { faUsers, faRocket, faUser, faLock, faWallet } from "@fortawesome/free-solid-svg-icons";

const accountSection = [
  {
    href: "/usuarios/perfil/",
    icon: faUser,
    title: "Perfil",
  },
] as SidebarItemsType[];

const infoSection = [
  {
    href: "/gerenciar-investimentos",
    icon: faRocket,
    title: "Investimentos",
    children: [
      {
        href: "/gerenciar-investimentos/listar",
        title: "Ver todos",
      },
      {
        href: "/gerenciar-investimentos/adicionar",
        title: "Adicionar novo",
      },
      {
        href: "/gerenciar-investimentos/status",
        title: "Status",
      },
      {
        href: "/gerenciar-investimentos/classificacao",
        title: "Classificação de risco",
      },
    ],
  },
  {
    href: "/investidores",
    icon: faUsers,
    title: "Investidores",
  },
  {
    href: "/carteira",
    icon: faWallet,
    title: "Transações",
    children: [
      {
        href: "/carteira/depositos",
        title: "Depósitos",
      },
      {
        href: "/carteira/saques",
        title: "Saques",
      },
      {
        href: "/carteira",
        title: "Extrato",
      },
    ],
  },
] as SidebarItemsType[];

const configSection = [
    {
      href: "/usuarios",
      icon: faLock,
      title: "Usuários",
      children: [
        {
          href: "/usuarios/listar",
          title: "Ver todos",
        },
        {
          href: "/usuarios/adicionar",
          title: "Adicionar novo",
        },
      ],
    },
  ] as SidebarItemsType[];

const navItems = [
  {
    title: "Gerenciar",
    pages: infoSection,
  },
  {
    title: "Configurar",
    pages: configSection,
  },
  {
    title: "Minha conta",
    pages: accountSection,
  },
];

export default navItems;