import { getAddressByCEP } from "cep-address-finder";
import { ErrorMessage, FormikErrors, FormikTouched } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import InputMask from "react-input-mask";

import { AddressForm } from "../../pages/user/user-address/interfaces";
import ErrorMessageCustom from "./ErrorMessageCustom";

interface AddressProps {
  setFieldValue: (field: string, value: any) => void;
  errors: FormikErrors<AddressForm>;
  touched: FormikTouched<AddressForm>;
  handleBlur: any;
  handleChange: any;
  values: AddressForm;
  liquidado?: boolean
}

const InputsAddress = ({
  setFieldValue,
  errors,
  touched,
  handleBlur,
  handleChange,
  values,
  ...rest
}: AddressProps) => {
  const [postalCode, setPostalCode] = useState<string | undefined>();
  useEffect(() => {
    setFieldValue("address.postalCode", postalCode);
    if (postalCode?.length === 8) {
      getAddressByCEP(postalCode)
        .then((response) => {
          if (response) {
            setFieldValue("state", response.state);
            setFieldValue("city", response.city);
            setFieldValue("address.neighborhood", response.neighborhood);
            setFieldValue("address.line_1", response.street);
          }
        })
        .catch((err) => {
          if (err.stack) {
            alert("Não foi encontrado um endereço com este cep");
          }
        });
    } else {
      setFieldValue("state", "");
      setFieldValue("city", "");
      setFieldValue("address.neighborhood", "");
    }
  }, [postalCode]);
  return (
    <Row>
      <Col xl="3" md="4" sm="6">
        <Form.Group className="mb-2">
          <Form.Label for="postalCode">
            <span style={{ color: "red" }}>*</span> Cep
          </Form.Label>
          <Form.Control
            as={InputMask}
            mask={"99999-999"}
            id="postalCode"
            type="tel"
            size="lg"
            readOnly={rest.liquidado}
            name="address.postalCode"
            value={values.address?.postalCode}
            isInvalid={Boolean(
              //@ts-ignore
              touched?.address?.postalCode && errors.address?.postalCode
            )}
            onBlur={handleBlur}
            onChange={(e: any) =>
              setPostalCode(e.target.value.replace(/\D+/g, ""))
            }
          />
          <ErrorMessage name="address.postalCode" render={ErrorMessageCustom} />
        </Form.Group>
      </Col>
      <Col xl="3" md="4" sm="6">
        <Form.Group className="mb-3">
          <Form.Label>Estado</Form.Label>
          <Form.Control
            type="text"
            size="lg"
            disabled={true}
            name="state"
            value={values.state}
          />
          <ErrorMessage name="state" render={ErrorMessageCustom} />
        </Form.Group>
      </Col>
      <Col xl="3" md="4" sm="6">
        <Form.Group className="mb-3">
          <Form.Label>Cidade</Form.Label>
          <Form.Control
            type="text"
            size="lg"
            name="city"
            value={values.city}
            disabled={true}
          />
          <ErrorMessage name="state" render={ErrorMessageCustom} />
        </Form.Group>
      </Col>
      <Col xl="3" md="4" sm="6">
        <Form.Label>Bairro</Form.Label>
        <Form.Group className="mb-3">
          <Form.Control
            type="text"
            size="lg"
            name="address.neighborhood"
            value={values?.address?.neighborhood}
            disabled={true}
          />
          <ErrorMessage
            name="address.neighborhood"
            render={ErrorMessageCustom}
          />
        </Form.Group>
      </Col>
      <Col xl="3" md="4" sm="6">
        <Form.Group className="mb-3">
          <Form.Label>Rua</Form.Label>
          <Form.Control
            type="text"
            size="lg"
            name="address.line_1"
            readOnly={rest.liquidado}
            value={values?.address?.line_1}
            isInvalid={
              //@ts-ignore
              touched.address?.line_1 && errors.address?.line_1
            }
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <ErrorMessage name="address.line_1" render={ErrorMessageCustom} />
        </Form.Group>
      </Col>
      <Col xl="2" md="3" sm="6">
        <Form.Label>Nº</Form.Label>
        <Form.Group className="mb-3">
          <Form.Control
            type="text"
            size="lg"
            name="address.addressNumber"
            value={values?.address?.addressNumber}
            readOnly={rest.liquidado}
            isInvalid={
              //@ts-ignore
              touched.address?.addressNumber &&
              //@ts-ignore
              errors.address?.addressNumber
            }
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <ErrorMessage
            name="address.addressNumber"
            render={ErrorMessageCustom}
          />
        </Form.Group>
      </Col>
      <Col xl="3" md="4" sm="6">
        <Form.Label>Complemento</Form.Label>
        <Form.Group className="mb-3">
          <Form.Control
            type="text"
            size="lg"
            name="address.line_2"
            readOnly={rest.liquidado}
            value={values?.address?.line_2}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Form.Group>
      </Col>
    </Row>
  );
};

export default InputsAddress;
