import { faPen, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Badge, Button, Card, Container, Table } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import {
  account_type,
  itens_per_page,
  statusCode,
} from "../../beans/enumerators";
import { messagesAction } from "../../beans/messages";
import Avatar from "../../components/lists/Avatar";
import Header from "../../components/lists/Header";
import PaginationCustom from "../../components/lists/Pagination";
import { baseURL } from "../../utils/axios";
import useToken from "../../utils/hooks/useToken";
import Filter from "./components/Filter";
import { UserDetail } from "./interfaces/user";
import { deleteUser, getUserAll } from "./services/user";
import BtnDropdown from "../../components/lists/BtnDropdown";

const UserList = () => {
  const { user } = useToken();

  const [nList, setNList] = useState<number>(0);
  const [list, setList] = useState<Array<UserDetail>>([]);
  const [pagination, setPagination] = useState<number>(0);
  const [filters, setFilters] = useState<any>({
    user_name: "",
    account_type: "",
    pagination: "1",
  });

  function verifyFilters(key: string, newValue: string) {
    let newFilters = filters;
    newFilters[key] = newValue;
    if (key !== "pagination") newFilters["pagination"] = "1";
    setFilters(newFilters);
    screenUpdate();
  }

  function screenUpdate() {
    getUserAll(filters).then((response) => {
      setList(response.list);
      setNList(response.total);
      response.total > 0
        ? setPagination(Math.ceil(response.total / itens_per_page.default))
        : setPagination(0);
    });
  }
  useEffect(() => {
    screenUpdate();
  }, []);

  const [openFilters, setOpenFilters] = useState(false);

  function handleDelete(uuid?: string | number) {
    if (uuid) {
      deleteUser(uuid.toString()).then((response) => {
        if (response.status !== statusCode.ok) {
          alert(response.statusText);
        } else {
          screenUpdate();
          let v = document.getElementsByTagName("table")[0].click;
        }
      });
    }
  }

  return (
    <React.Fragment>
      <Helmet title="Usuários" />
      <Container fluid className="p-0">
        <Header
          title="Usuários"
          verifyFilters={verifyFilters}
          filterName="user_name"
          openFilters={openFilters}
          setOpenFilters={setOpenFilters}
        />
        <Filter
          openFilters={openFilters}
          verifyFilters={verifyFilters}
          theFilters={filters}
        />
        <div className="mt-4">{nList} registros</div>
        <Card className="flex-fill w-100 mt-3">
          <p className="d-md-none pt-3 ps-3">
            <b>Lista de usuários</b>
          </p>
          <Table striped className="mq-table-big">
            <thead>
              <tr>
                <th>Nome do usuário</th>
                <th className="text-center">E-mail</th>
                <th className="text-center">Tipo de usuário</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {!!list &&
                list.map((item) => (
                  <tr>
                    <td>
                      <div className="d-flex align-items-center">
                        <Avatar
                          src={
                            item.user?.photo_image
                              ? baseURL +
                                "/uploads/user/" +
                                item.user.photo_image
                              : ""
                          }
                          name={item.user?.user_name}
                          showName={true}
                        />
                      </div>
                    </td>
                    <td className="text-md-center">{item.login?.email}</td>
                    <td className="text-md-center">
                      <Badge bg="dark">
                        {item.login?.account_type === account_type.admin
                          ? "Administrador"
                          : "Cliente"}
                      </Badge>
                    </td>
                    <td className="text-md-end mq-actions">
                      <Link to={"/usuarios/" + item.user?.uuid_user + "/"}>
                        <Button variant="primary">
                          <FontAwesomeIcon icon={faPen} />
                          <span className="d-none d-xl-inline-block ps-1">
                            {messagesAction.edit}
                          </span>
                        </Button>
                      </Link>
                      {user?.uuid_user !== item.user?.uuid_user && (
                        <BtnDropdown
                          btn={messagesAction.delete}
                          icon={faTimesCircle}
                          title="Excluir / Desativar"
                          msg="Se o usuário possuir dependências não será excluído e sim desativado."
                          handleAction={handleDelete}
                          id={item.user?.uuid_user}
                        />
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Card>
        <PaginationCustom
          pagination={pagination}
          filter={filters.pagination}
          verifyFilters={verifyFilters}
        />
      </Container>
    </React.Fragment>
  );
};

export default UserList;
