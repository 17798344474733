import { ErrorMessage, Formik, FormikHelpers } from "formik";
import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import CurrencyInput from "react-currency-input-field";
import { useNavigate } from "react-router-dom";

import { statusCode } from "../../../beans/enumerators";
import { messagesAction, messagesForm } from "../../../beans/messages";
import ErrorMessageCustom from "../../../components/forms/ErrorMessageCustom";
import { getToken } from "../../../utils/token";
import { checkLogin, signInApi } from "../../auth/services/auth";
import Yup from "../../error/Yup";
import { LiquidationLoginForm } from "../interfaces/investments";
import { liquidInvestors } from "../services/investments";

interface ModalLiquidationLogin {
    show: boolean;
    setModalShow: React.Dispatch<React.SetStateAction<boolean>>;
    uuid_investment: string;
    rentability: string,
    setValuesForm: () => {}
}

const ModalLiquidationLogin = ({
    setModalShow,
    show,
    uuid_investment,
    rentability,
    setValuesForm,
    ...rest
}: ModalProps | ModalLiquidationLogin) => {
    const navigate = useNavigate();
    const [initialValues, setInitialValues] = useState<LiquidationLoginForm>({
        email: '',
        password: '',
        rentability,
        uuid_investment,
        submit: false
    });
    const formikSubmit = async (
        values: LiquidationLoginForm,
        {
            setErrors,
            setStatus,
            setSubmitting,
        }: FormikHelpers<LiquidationLoginForm>
    ) => {
        let { submit, ...dataLogin } = values;

        await checkLogin(values).then((response) => {
            if (response.status !== statusCode.ok) {
                const message: string = response.statusText || messagesForm.error;
                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
            }
            else {
                const tokenLogin: any = jwtDecode(response.statusText);
                const token: any = jwtDecode(String(getToken()));
                if (token.uuid_user != tokenLogin.uuid_user) {
                    setStatus({ success: false });
                    setErrors({ submit: "A conta deve ser a mesma que esta logada" });
                    setSubmitting(false);
                } else {
                    liquidInvestors({ uuid_investment, rentability }).then((response) => {
                        if (response.status !== statusCode.ok) {
                            const message: string = response.statusText || messagesForm.error;
                            setStatus({ success: false });
                            setErrors({ submit: message });
                            setSubmitting(false);
                        } else {
                            navigate('/gerenciar-investimentos/listar')
                        }
                    })
                }
            }
        });

    };

    function handleClose() {
        setValuesForm({});
        setModalShow(false);
    }

    return (
        <Modal
            show={show}
            onHide={() => {
                handleClose();
            }}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={Yup.object({
                    email: Yup.string().required(messagesForm.required),
                    password: Yup.string().required(messagesForm.required),
                    rentability: Yup.number().nullable()
                })}
                validateOnBlur={true}
                onSubmit={formikSubmit}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    isSubmitting,
                    values,
                    status,
                    setFieldValue,
                }) => (
                    <>
                        <Form onSubmit={handleSubmit}>

                            <Form.Group className="m-3">
                                <h3>Confirme seu usuário</h3>
                            </Form.Group>
                            <Form.Group className="m-3">
                                <Form.Label>E-mail</Form.Label>
                                <Form.Control
                                    size="lg"
                                    type="email"
                                    name="email"
                                    value={values.email}
                                    isInvalid={Boolean(touched.email && errors.email)}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                                {!!touched.email && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>

                            <Form.Group className="m-3">
                                <Form.Label>Senha</Form.Label>
                                <Form.Control
                                    size="lg"
                                    type="password"
                                    name="password"
                                    value={values.password}
                                    isInvalid={Boolean(touched.password && errors.password)}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                                {!!touched.password && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.password}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                            {errors.submit && (
                                <Alert className="mt-4" variant="danger">
                                    <div className="alert-message">{errors.submit}</div>
                                </Alert>
                            )}

                            <div className="m-4">
                                <Button
                                    type="submit"
                                    variant="primary"
                                    size="lg"
                                    disabled={isSubmitting}
                                >
                                    Enviar
                                </Button>
                            </div>
                        </Form>
                    </>
                )}
            </Formik>
        </Modal>
    );
};

export default ModalLiquidationLogin;
