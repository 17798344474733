import React from "react";
import { Helmet } from "react-helmet-async";

import { Container, Tab } from "react-bootstrap";

import Header from "../../components/lists/Header";
import FormLogin from "./components/FormLogin";

const UserCreate = () => (
  <React.Fragment>
    <Helmet title="Adicionar usuário" />
    <Container fluid className="p-0">
      <Header title="Adicionar usuário" />
      <div className="tab">
        <Tab.Container defaultActiveKey="form-login">
          <Tab.Content>
            <Tab.Pane eventKey="form-login">
              <FormLogin />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </Container>
  </React.Fragment>
);

export default UserCreate;
