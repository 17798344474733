import axiosInstance from "../../../utils/axios";
import { verifyResponse } from "../../../utils/verify-response";
import { verifyData, verifyDataArray, verifyDataList } from "../../../utils/verify-response";
import { InvestmentStatusDisplay, RentabilityMonthChart, StatisticsItems } from "../interfaces/dashboard";

export async function getDashboardStatistics(): Promise<Array<StatisticsItems> | undefined> {
    return axiosInstance.get('/dashboard/statistics').then(response => {
        return verifyDataArray(response);
    }).catch(error => {
        return verifyDataArray(error);
    });
}

export async function getLastFourInvestments(): Promise<Array<InvestmentStatusDisplay> | undefined> {
    return axiosInstance.get('/dashboard/lastFourInvestments').then(response => {
        return verifyDataArray(response);
    }).catch(error => {
        return verifyDataArray(error);
    });
}

export async function getWalletBalanceChartMonthly(): Promise<RentabilityMonthChart | undefined> {
    return axiosInstance.get('/dashboard/wallet').then(response => {
        return verifyData(response);
    }).catch(error => {
        return verifyData(error);
    });
}