import { faBan, faCheckCircle, faMoneyBillAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Container, Table } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

import { itens_per_page, situation_type, statusCode, transaction_type } from "../../beans/enumerators";
import { messagesAction } from "../../beans/messages";
import Avatar from "../../components/lists/Avatar";
import BtnDropdown from "../../components/lists/BtnDropdown";
import Header from "../../components/lists/Header";
import PaginationCustom from "../../components/lists/Pagination";
import { baseURL } from "../../utils/axios";
import { SocketContext } from "../../utils/contexts/SocketContext";
import { formatMoney } from "../../utils/format";
import useToken from "../../utils/hooks/useToken";
import Filter from "./components/Filter";
import ModalTransaction from "./components/ModalTransaction";
import TdSituation from "./components/TdSituation";
import { ListTransaction } from "./interfaces/transaction";
import { getAllTransactions, handleTransaction } from "./services/List";

const UserInvestors = () => {
  const [nList, setNList] = useState<number>(0);
  //const socket = useContext(SocketContext);
  const [transactionInList, setTransactionInList] = useState<
    ListTransaction["list"] | undefined
  >();
  const [pagination, setPagination] = useState<number>(0);
  const token = useToken();
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [filters, setFilters] = useState<any>({
    user_name: "",
    data_period: "all",
    situation: Object.values(situation_type).filter(
      (el) => el != situation_type.invested && typeof el == "number"
    ),
    type: transaction_type.in,
    value_max: "",
    value_min: "",
    pagination: "1",
  });

  function verifyFilters(key: string, newValue: string) {
    let newFilters = filters;
    newFilters[key] = newValue;
    if (key !== "pagination") newFilters["pagination"] = "1";
    setFilters(newFilters);
    screenUpdate();
  }

  async function screenUpdate() {
    await getAllTransactions(filters).then((response) => {
      setTransactionInList(response.list);
      setNList(response.total);
      response.total > 0
        ? setPagination(Math.ceil(response.total / itens_per_page.default))
        : setPagination(0);
    });
  }

  async function handleButton(
    uuid_transaction: string,
    situation: situation_type
  ) {
    await handleTransaction({ uuid_transaction, situation: situation }).then(
      (response) => {
        if (response.status !== statusCode.ok) {
          alert(response.statusText);
        } else {
          screenUpdate();
        }
      }
    );
  }

  function handleApprove(uuid?: string | number) {
    if (uuid) {
      handleButton(uuid.toString(), situation_type.approved);
    }
  }
  function handleCancel(uuid?: string | number) {
    if (uuid) {
      handleButton(
        uuid.toString(),
        token.isAdmin ? situation_type.canceled : situation_type.canceledByUser
      );
    }
  }

  useEffect(() => {
    screenUpdate();
  }, []);

  const [openFilters, setOpenFilters] = useState(false);

  return (
    <React.Fragment>
      <Helmet title="Depósitos na carteira" />
      <Container fluid className="p-0">
        <Header
          title="Depósitos na carteira"
          verifyFilters={verifyFilters}
          openFilters={openFilters}
          setOpenFilters={setOpenFilters}
        />

        <Filter
          openFilters={openFilters}
          verifyFilters={verifyFilters}
          theFilters={filters}
          token={token}
          transaction_type={transaction_type.in}
          extract={false}
        />
        <div className="mt-4">{nList} registros</div>
        <Card className="flex-fill w-100 mt-3">
          <Table striped className="my-0">
            <thead>
              <tr>
                {token.isAdmin && <th>Nome do usuário</th>}
                <th className={token.isAdmin ? "text-center" : ""}>Data</th>
                <th className="text-center">Valor</th>
                <th className="text-center">Status</th>
                <th className="text-end">
                  {!token.isAdmin && (
                    <Button
                      onClick={() => {
                        setModalShow(true);
                      }}
                      variant="success"
                      className="shadow-sm"
                    >
                      <FontAwesomeIcon icon={faMoneyBillAlt} />{" "}
                      {messagesAction.deposit}
                    </Button>
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {!!transactionInList &&
                transactionInList.map((item, key) => (
                  <>
                    <tr key={key}>
                      {token.isAdmin && (
                        <td>
                          <Avatar
                            src={
                              item.photo_image
                                ? baseURL + "/uploads/user/" + item.photo_image
                                : ""
                            }
                            name={item.user_name}
                            showName={true}
                          />
                        </td>
                      )}
                      <td className={token.isAdmin ? "text-center" : ""}>
                        {moment(item.dt_created)
                          .format("DD/MM/YYYY")
                          .toString()}
                      </td>
                      <td className="text-center">
                        R$ {formatMoney(Number(item.value).toString())}
                      </td>
                      <TdSituation item={item} />
                      <td className="text-end mq-actions">
                        {item.situation == situation_type.analysis && (
                          <>
                            {token.isAdmin && (
                              <BtnDropdown
                                btn="Aprovar"
                                icon={faCheckCircle}
                                title="Aprovar depósito"
                                msg="O valor será creditado na carteira do usuário."
                                handleAction={handleApprove}
                                id={item.uuid_transaction}
                                variant_color="success"
                              />
                            )}

                            <BtnDropdown
                              btn="Cancelar"
                              icon={faBan}
                              title="Cancelar depósito"
                              msg="O valor NÃO será creditado na carteira do usuário."
                              handleAction={handleCancel}
                              id={item.uuid_transaction}
                            />
                          </>
                        )}
                      </td>
                    </tr>
                  </>
                ))}
            </tbody>
          </Table>
        </Card>

        {modalShow && (
          <ModalTransaction
            show={modalShow}
            setModalShow={setModalShow}
            screenUpdate={screenUpdate}
            title={"Novo depósito"}
            labelMessage={"Valor a depositar"}
            type={1}
          />
        )}
        <PaginationCustom
          pagination={pagination}
          filter={filters.pagination}
          verifyFilters={verifyFilters}
        />
      </Container>
    </React.Fragment>
  );
};

export default UserInvestors;
