import * as React from "react";
import { Navigate } from "react-router-dom";

import useToken from "../../utils/hooks/useToken";

interface AuthGuardType {
  children: React.ReactNode;
}

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }: AuthGuardType) {
  const { isValid } = useToken();

  if (!isValid) {
    return <Navigate to="/acesso/entrar" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default AuthGuard;
