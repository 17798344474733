import { faDollarSign, faPiggyBank, faRocket, faWallet } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Badge, Card, Col, Row } from "react-bootstrap";

import { StatisticsItems } from "../interfaces/dashboard";

interface StatisticsProps {
  statistics: Array<StatisticsItems | undefined>;
}

const Statistics = ({ statistics }: StatisticsProps) => {
  const icons = [faRocket, faPiggyBank, faDollarSign, faWallet];

  return (
    <Row>
      {statistics.length && statistics.map((item, key) => (
        item &&
        <Col key={key} md="6" xl className="d-flex">
          <Card className="flex-fill">
            <Card.Body className=" py-4">
              <div className="d-flex align-items-start">
                <div className="flex-grow-1">
                  <h3 className="mb-2">{item.data}</h3>
                  <p className="mb-2">{item.name}</p>
                  <div className="mb-0">
                    <Badge bg="" className={"me-2 " + (Number(item.badge) > 0 ? "badge-soft-success" : "badge-soft-danger")}>
                      {Number(item.badge) > 0 ? `+${item.badge}%` : `${item.badge}%`}
                    </Badge>
                    <span className="text-muted">{item.text}</span>
                  </div>
                </div>
                <div className="d-inline-block ms-3">
                  <div className="stat">
                    <FontAwesomeIcon
                      icon={icons[key]}
                      className="align-middle text-success"
                    />
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default Statistics;
