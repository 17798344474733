import React, { forwardRef } from "react";
import { Col, ListGroup, Row } from "react-bootstrap";
import { X } from "react-feather";

interface NavbarDropdownItemType {
  icon: React.ReactNode;
  title: string;
  description: string;
  time: string;
  spacing?: boolean;
  handleButton: (idNotification: string) => void;
  id: string | undefined
}

const NavbarDropdownItem = ({
  icon,
  title,
  description,
  time,
  spacing,
  handleButton,
  id

}: NavbarDropdownItemType) => {

  return (
    <ListGroup.Item>
      <Row className="align-items-center" >
        <Col xs="auto">{icon}</Col>
        <Col xs className={spacing ? "pl-2" : ""}>
          <div className="text-dark">{title}</div>
          <div className="text-muted small mt-1">{description}</div>
          <div className="text-muted small mt-1">{time}</div>
        </Col>
        <Col onClick={() => { handleButton(String(id)) }} xs="auto"><X size={10} style={{ cursor: 'pointer' }} /></Col>
      </Row>
    </ListGroup.Item>
  )
};

export default NavbarDropdownItem;