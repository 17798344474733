import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { AlertCircle, AlertTriangle, Bell, CheckCircle } from "react-feather";

import { baseURL } from "../../utils/axios";
import { ListNotifications, SocketContext } from "../../utils/contexts/SocketContext";
import useSidebar from "../sidebar/hooks/useSidebar";
import NavbarDropdown from "./NavbarDropdown";
import NavbarDropdownItem from "./NavbarDropdownItem";
import NavbarUser from "./NavbarUser";
import { deleteNotification, getNotifications } from "./services/Navbar";

const NavbarComponent = () => {
  const { isOpen, setIsOpen } = useSidebar();
  const { io } = useContext(SocketContext);
  const [notifications, setNotifications] = useState<Array<ListNotifications>>([]) //datas.lista;
  const [deleted, setDeleted] = useState(false);


  const handleButton = (idNotification: string) => {
    if (idNotification) {
      deleteNotification(idNotification).then((data) => {
        data.status == 200 && setDeleted((prevVal) => { return !prevVal })
      });
    }
  }

  const getAllNotifications = async () => {
    setNotifications(await getNotifications());
  }

  useEffect(() => {
    getAllNotifications();
  }, [deleted]);

  useEffect(() => {
    io.on('notification', () => {
      getAllNotifications()
    })

  }, [io])

  return (
    <Navbar variant="light" expand className="navbar-bg" >
      <span
        className="sidebar-toggle d-flex"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <i className="hamburger align-self-center" />
      </span>

      <Navbar.Collapse >
        <Nav className="navbar-align"  >
          <NavbarDropdown
            header="novas notificações"
            icon={Bell}
            count={notifications?.length}
            showBadge
            scroll={true}
          >
            {notifications &&
              notifications.map((item, key) => {

                let icon = <AlertTriangle size={18} className="text-warning" />;

                if (item.type === "important") {
                  icon = <AlertCircle size={18} className="text-danger" />;
                }

                if (item.type === "success") {
                  icon = <CheckCircle size={18} className="text-success" />;
                }
                return (
                  <NavbarDropdownItem
                    key={key}
                    icon={icon}
                    title={item.title}
                    description={item.description}
                    time={item.time}
                    handleButton={handleButton}
                    id={item.id_notification}
                  />
                );
              })}
          </NavbarDropdown>

          <NavbarUser />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarComponent;
