import React from "react";

import logoBg from "../../../assets/img/login.jpg";
import { Col } from "react-bootstrap";

const LogoWhite = () => (
  <Col lg={6} className="h-100 d-none d-lg-block">
    <div className="d-flex h-100 align-items-center justify-content-center main-card-login">
      <img src={logoBg} alt="Logo" className="w-100 rounded-lg" />
    </div>
  </Col>
);

export default LogoWhite;
