import { AxiosResponse } from 'axios';

import axiosInstance from "../../../utils/axios";
import { verifyData, verifyDataList, verifyResponse } from "../../../utils/verify-response";
import { Classification } from './interfaces'

export async function getClassificationAll(): Promise<Array<Classification>> {
    return axiosInstance.get('/investment/classification/').then(response => {
        return verifyDataList(response);
    }).catch(error => {
        return verifyDataList(error);
    });
}

export async function getClassification(id: number): Promise<Classification | undefined> {
    return axiosInstance.get('/investment/classification/' + id).then(response => {
        return verifyData(response);
    }).catch(error => {
        return verifyData(error);
    });
}

export async function patchClassification(classification: Classification): Promise<AxiosResponse> {
    return axiosInstance.patch('/investment/classification/', classification).then(response => {
        return verifyResponse(response);
    }).catch(error => {
        return verifyResponse(error);
    });
}

export async function deleteClassification(id: number): Promise<AxiosResponse> {
    return axiosInstance.delete('/investment/classification/' + id).then(response => {
        return verifyResponse(response);
    }).catch(error => {
        return verifyResponse(error);
    });
}