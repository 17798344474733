import axios from "axios";

import { getToken } from "./token";

const baseDev = "http://192.168.100.10:9070";
//const baseDev = "http://localhost:9070";
const baseProd = "https://koparinvestimentos.com.br:9070";

export const baseURL = baseProd;

const axiosInstance = axios.create({ baseURL });

axiosInstance.interceptors.request.use(function (config) {
  config.headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return config;
}, function (error) {
  return error;
});
axiosInstance.interceptors.response.use(
  (response) => { return response },
  (error) => {
    return error.response && error.response.data || "Erro"
  }
);

export default axiosInstance;