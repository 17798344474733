import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Col, Row } from "react-bootstrap";

import SignIn from "./components/SignIn";
import Logo from "./components/Logo";
import LogoWhite from "./components/LogoWhite";

const SignInPage = () => (
  <React.Fragment>
    <Helmet title="Entrar" />

    <Row className="h-100 align-items-center py-4 g-0">
      <LogoWhite />
      <Col lg={6}>
        <Logo />
        <div className="block-max-width mx-auto ps-4 ps-xl-0">
          <div className="text-center">
            <h2>Bem-vindo(a) de volta</h2>
            <p className="lead">
              Informe seus dados para acessar ou{" "}
              <Link to="/acesso/cadastrar/">cadastre-se aqui</Link>
            </p>
          </div>
          <SignIn />
        </div>
      </Col>
    </Row>
  </React.Fragment>
);

export default SignInPage;
