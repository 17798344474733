import axiosInstance from "../../utils/axios";
import { verifyDataPagination } from "../../utils/verify-response";
import { GetAllInvestmentHasUser } from "./interfaces";

export async function getAllInvestmentsHasUsers(filters: any): Promise<GetAllInvestmentHasUser> {
    return await axiosInstance.get('/investment/investmentHasUsers/?' + new URLSearchParams(filters).toString()).then(response => {
        return verifyDataPagination(response);
    }).catch(error => {
        return verifyDataPagination(error);
    });
}