import React, { useContext, useEffect, useState } from "react";

import { DashboardContext } from "../../utils/contexts/DashboardContext";
import Statistics from "./components/Statistics";
import { StatisticsItems } from "./interfaces/dashboard";
import { getDashboardStatistics } from "./services/dashboard";

const AdminDashboard = () => {

  let useDashboard = useContext(DashboardContext);

  useEffect(() => {
    useDashboard.updateStatistics();
  }, [])
  const statistics = useDashboard.statistics;



  return (
    <React.Fragment>
      <Statistics statistics={statistics} />
    </React.Fragment>
  );
};

export default AdminDashboard;
