import { useEffect, useState } from "react";
import { Card, Col, Collapse, Row } from "react-bootstrap";
import CurrencyInput from "react-currency-input-field";

import { Status } from "../../investments/status/interfaces";
import { getStatusAll } from "../../investments/status/services";

interface FilterProps {
  verifyFilters: Function;
  openFilters: boolean;
  theFilters: any;
}

const Filter = ({ verifyFilters, openFilters, theFilters }: FilterProps) => {
  const [status, setStatus] = useState<Array<Status>>([{}]);

  useEffect(() => {
    getStatusAll().then((response) => {
      setStatus(response);
    });
  }, []);

  return (
    <Collapse in={openFilters} className="mq-filter p-3 pt-0">
      <Card>
        <Row>
          <Col lg="auto" sm="6" className="mt-3">
            <label className="form-label">Nome do investimento</label>
            <input
              type="text"
              className="form-control input-filter"
              onChange={(e) => verifyFilters("investment", e.target.value)}
            />
          </Col>
          <Col lg="auto" sm="6" className="mt-3">
            <label className="form-label">Status</label>
            <select
              className="form-control form-select input-filter"
              onChange={(e) => verifyFilters("fk_id_status", e.target.value)}
            >
              <option value="">Todos</option>
              {status.map((item) => {
                return <option value={item.id_status}>{item.status}</option>;
              })}
            </select>
          </Col>
          <Col lg="auto" className="mt-3">
            <label className="form-label">Total investido: De - Até</label>
            <Row>
              <Col>
                <CurrencyInput
                  className="form-control input-filter"
                  onValueChange={(v) =>
                    verifyFilters("investment_value_from", v ? v : "")
                  }
                  decimalSeparator=","
                  groupSeparator="."
                  datatype=""
                  placeholder="De R$"
                />
              </Col>
              <Col>
                <CurrencyInput
                  className="form-control input-filter"
                  onValueChange={(v) =>
                    verifyFilters("investment_value_to", v ? v : "")
                  }
                  decimalSeparator=","
                  groupSeparator="."
                  datatype=""
                  placeholder="Até R$"
                />
              </Col>
            </Row>
          </Col>
          <Col lg="auto" className="mt-3">
            <label className="form-label">Data de investimento: De - Até</label>
            <Row>
              <Col>
                <div className="position-relative">
                  <input
                    type="date"
                    className="form-control input-filter"
                    onChange={(e) =>
                      verifyFilters("investment_dt_from", e.target.value)
                    }
                  />
                </div>
              </Col>
              <Col>
                <div className="position-relative">
                  <input
                    type="date"
                    className="form-control input-filter"
                    onChange={(e) =>
                      verifyFilters("investment_dt_to", e.target.value)
                    }
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </Collapse>
  );
};

export default Filter;
