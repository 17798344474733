import moment from "moment";
import React, { useEffect, useState } from "react";
import { Badge, Button, Col, Form, ListGroup, ProgressBar, Row } from "react-bootstrap";

import InputsAddress from "../../../components/forms/InputsAddress";
import Header from "../../../components/lists/Header";
import { formatMoney, formatNumber } from "../../../utils/format";
import { Classification } from "../classification/interfaces";
import { getClassification } from "../classification/services";
import Investment from "../interfaces/investments";
import { Status } from "../status/interfaces";
import { getStatus } from "../status/services";

interface InvestmentViewProps {
  investmentSelected: Investment;
  setOpenModal: Function;
}

const ViewMain = ({
  investmentSelected,
  setOpenModal
}: InvestmentViewProps) => {
  const [status, setStatus] = useState<Status>();
  const [classification, setClassification] = useState<Classification>();

  useEffect(() => {
    if (investmentSelected.investment?.fk_id_status)
      getStatus(Number(investmentSelected.investment?.fk_id_status)).then(
        (response) => {
          if (response) setStatus(response);
        }
      );
    if (investmentSelected.investment?.fk_id_classification)
      getClassification(
        investmentSelected.investment?.fk_id_classification
      ).then((response) => {
        if (response) setClassification(response);
      });
  }, []);

  return (
    <>
      <Badge
        className="mb-2"
        bg="none"
        style={{ backgroundColor: status?.color }}
      >
        {status?.status}
      </Badge>
      <Header title={"" + investmentSelected?.investment?.investment} />
      <ListGroup variant="flush">
        <ListGroup.Item className="px-0 pt-0 pb-4">
          <p className="text-muted mb-0">
            Investimento captado<span className="float-end">Objetivo</span>
          </p>
          <p className="mb-2 fw-bold">
            R$ {formatMoney(investmentSelected.investment?.totalInvested)}
            <span className="float-end">
              R$ {formatMoney(investmentSelected.investment?.value_goal)}
            </span>
          </p>

          <ProgressBar
            className="progress-sm"
            now={
              (Number(investmentSelected.investment?.totalInvested) * 100) /
              Number(investmentSelected.investment?.value_goal)
            }
          />
        </ListGroup.Item>
        <ListGroup.Item className="px-0">
          <span className="text-muted">Rentabilidade Projetada</span>
          <span className="float-end fw-bold">
            {formatNumber(investmentSelected.investment?.rentability)}%
          </span>
        </ListGroup.Item>
        <ListGroup.Item className="px-0">
          <span className="text-muted">Investidores</span>
          <span className="float-end fw-bold">
            {investmentSelected.investment?.totalInvestors}
          </span>
        </ListGroup.Item>
        <ListGroup.Item className="px-0">
          <span className="text-muted">Investimento Mínimo</span>
          <span className="float-end fw-bold">
            R$ {formatMoney(investmentSelected.investment?.minimal_investment)}
          </span>
        </ListGroup.Item>
        <ListGroup.Item className="px-0">
          <span className="text-muted">Resgate</span>
          <span className="float-end fw-bold">
            {moment(investmentSelected.investment?.redemption).format(
              "DD/MM/YYYY"
            )}
          </span>
        </ListGroup.Item>
        <ListGroup.Item className="px-0">
          <span className="text-muted">Classificação de risco</span>
          <span className="float-end fw-bold">
            {classification?.acronym} - {classification?.description}
          </span>
        </ListGroup.Item>
        <ListGroup.Item className="px-0">
          <span className="text-muted">Localização</span>
          <span className="float-end fw-bold">
            {investmentSelected.city} - {investmentSelected.state}
          </span>
        </ListGroup.Item>
      </ListGroup>
      {status?.is_open && (
        <Button
          variant="yellow"
          size="lg"
          className="mt-4"
          onClick={() => setOpenModal(true)}
        >
          Investir
        </Button>
      )}
    </>
  );
};

export default ViewMain;