import moment from "moment";
import React, { useEffect, useState } from "react";
import { Card, Dropdown } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import { MoreHorizontal } from "react-feather";

import { THEME_PALETTE_LIGHT } from "../../../beans/constants";
import { getAllMonths } from "../../../utils/functions/getAllMonths";
import { RentabilityMonthChart } from "../interfaces/dashboard";
import { getWalletBalanceChartMonthly } from "../services/dashboard";

require('moment/locale/pt-br');

const BarChart = () => {

  const months = getAllMonths().map((item) => { return item.monthName });
  const [rentabilityMonthChart, setRentabilityMonthChart] = useState<RentabilityMonthChart>({ patternData: [{}], cdiData: [{}] })
  useEffect(() => {

    getWalletBalanceChartMonthly().then((response) => {
      if (response) {
        setRentabilityMonthChart(response);
      }
    })

  }, []);


  const palette = THEME_PALETTE_LIGHT;

  const data = {
    labels: months,
    datasets: [
      {
        label: "Rendimento",
        backgroundColor: palette.primary,
        borderColor: palette.primary,
        hoverBackgroundColor: palette.primary,
        hoverBorderColor: palette.primary,
        data: months.map((month) => {
          if (rentabilityMonthChart.patternData) {
            const filteredItems = rentabilityMonthChart.patternData.filter((item) => {
              return moment(item.month).format("MMMM") === month;
            });
            const values = filteredItems.map((item) => {
              return Number(item.rentability);
            });
            return values.length > 0 ? values[0] : false; // retorna false se não houver valores para o mês
          }
        }),
        barPercentage: 0.325,
        categoryPercentage: 0.5,
      },
      {
        label: "CDI",
        backgroundColor: palette["primary-light"],
        borderColor: palette["primary-light"],
        hoverBackgroundColor: palette["primary-light"],
        hoverBorderColor: palette["primary-light"],
        data: months.map((month) => {
          if (rentabilityMonthChart.cdiData) {
            const filteredItems = rentabilityMonthChart.cdiData.filter((item) => {
              return moment(item.month).format("MMMM") === month;
            });
            const values = filteredItems.map((item) => {
              return Number(item.rentability);
            });
            return values.length > 0 ? values[0] : false; // retorna false se não houver valores para o mês

          }
        }),
        barPercentage: 0.325,
        categoryPercentage: 0.5,
      }
    ],
  };

  const options = {
    maintainAspectRatio: false,
    cornerRadius: 15,
    legend: {
      position: "bottom" as const,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            stepSize: 1,
            callback: (value: any) => `${value}%`,
          },
          stacked: true,
        },
      ],
      xAxes: [
        {
          gridLines: {
            color: "transparent",
          },
          stacked: true,
        },
      ],
    },

    tooltips: {
      callbacks: {
        label: (tooltipItem: any, data: any) => {
          const datasetLabel = data.datasets[tooltipItem.datasetIndex].label;
          const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return `${datasetLabel}: ${value}%`;
        }
      }
    }
  };

  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        <div className="card-actions float-end">
          <Dropdown align="end">
            <Dropdown.Toggle as="a" bsPrefix="-">
              <span className="text-muted px-2 d-inline-block">Rendimento x CDI</span>
              {/*<MoreHorizontal />*/}
            </Dropdown.Toggle>
            {/* <Dropdown.Menu>
              <Dropdown.Item>CDB</Dropdown.Item>
              <Dropdown.Item>Tesouro Direto</Dropdown.Item>
              <Dropdown.Item>Poupança</Dropdown.Item>
            </Dropdown.Menu> */}
          </Dropdown>
        </div>
        <Card.Title className="mb-0">Rendimento mensal em {moment().format('YYYY')} </Card.Title>
      </Card.Header>
      <Card.Body className="d-flex">
        <div className="align-self-center w-100">
          <div className="chart chart-lg">
            <Bar data={data} options={options} />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default BarChart;
