import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import { statusCode } from "../../../beans/enumerators";
import { messagesAction, messagesForm } from "../../../beans/messages";
import { UserForm } from "../../user/interfaces/user";
import UserType from "../../user/interfaces/user-type";
import { postUser } from "../../user/services/user";
import { getUserTypeAll } from "../../user/services/user-type";

function SignUp() {
  const [sent, setSent] = useState(false);
  const initialValues: UserForm = {
    submit: false,
    user: { fk_id_user_type: 1 },
  };

  const [userTypeList, setUserTypeList] = useState<Array<UserType>>([]);
  useEffect(() => {
    getUserTypeAll().then((response) => setUserTypeList(response));
  }, []);

  return sent ? (
    <Alert variant="success">
      <div className="alert-message">
        Conta criada com sucesso, <Link to="/acesso/entrar/">acesse aqui</Link>.
      </div>
    </Alert>
  ) : (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        user: Yup.object().shape({
          user_name: Yup.string().max(150).required(messagesForm.required),
        }),
        login: Yup.object().shape({
          email: Yup.string()
            .email(messagesForm.invalid)
            .max(100)
            .required(messagesForm.required),
          password: Yup.string().max(50).required(messagesForm.required),
          confirm_password: Yup.string()
            .max(50)
            .required(messagesForm.required)
            .oneOf([Yup.ref("password"), null], messagesForm.password),
        }),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        delete values.login?.confirm_password;
        if (values.user?.user_name)
          values.user.nickname = values.user.user_name.split(" ")[0];

        let response = await postUser(values);
        if (response.status !== statusCode.created) {
          const message: string = response.statusText || messagesForm.error;
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        } else {
          setSent(true);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Tipo de cadastro</Form.Label>
            <Form.Select
              size="lg"
              name="user.fk_id_user_type"
              value={values.user?.fk_id_user_type}
              onBlur={handleBlur}
              onChange={handleChange}
            >
              {userTypeList.map((item) => (
                <option value={item.id_user_type}>{item.type}</option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Nome completo / Razão social</Form.Label>
            <Form.Control
              size="lg"
              type="text"
              name="user.user_name"
              value={values.user?.user_name}
              isInvalid={Boolean(
                //@ts-ignore
                touched.user?.user_name && errors.user?.user_name
              )}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {
              //@ts-ignore
              !!touched.user?.user_name && (
                <Form.Control.Feedback type="invalid">
                  {
                    //@ts-ignore
                    errors.user?.user_name
                  }
                </Form.Control.Feedback>
              )
            }
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>E-mail</Form.Label>
            <Form.Control
              size="lg"
              type="email"
              name="login.email"
              value={values.login?.email}
              isInvalid={Boolean(
                //@ts-ignore
                touched.login?.email && errors.login?.email
              )}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {
              //@ts-ignore
              !!touched.login?.email && (
                <Form.Control.Feedback type="invalid">
                  {
                    //@ts-ignore
                    errors.login?.email
                  }
                </Form.Control.Feedback>
              )
            }
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Senha</Form.Label>
            <Form.Control
              size="lg"
              type="password"
              name="login.password"
              value={values.login?.password}
              isInvalid={Boolean(
                //@ts-ignore
                touched.login?.password && errors.login?.password
              )}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {
              //@ts-ignore
              !!touched.login?.password && (
                <Form.Control.Feedback type="invalid">
                  {
                    //@ts-ignore
                    errors.login?.password
                  }
                </Form.Control.Feedback>
              )
            }
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Confirmar senha</Form.Label>
            <Form.Control
              size="lg"
              type="password"
              name="login.confirm_password"
              value={values.login?.confirm_password}
              isInvalid={Boolean(
                //@ts-ignore
                touched.login?.confirm_password &&
                //@ts-ignore
                errors.login?.confirm_password
              )}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {
              //@ts-ignore
              !!touched.login?.confirm_password && (
                <Form.Control.Feedback type="invalid">
                  {
                    //@ts-ignore
                    errors.login?.confirm_password
                  }
                </Form.Control.Feedback>
              )
            }
          </Form.Group>

          {errors.submit && (
            <Alert className="mt-4" variant="danger">
              <div className="alert-message">{errors.submit}</div>
            </Alert>
          )}

          <div className="mt-4">
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={isSubmitting}
            >
              {messagesAction.create}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default SignUp;
