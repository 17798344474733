import React from "react";
import { Helmet } from "react-helmet-async";

import { Container, Tab } from "react-bootstrap";

import Header from "../../components/lists/Header";
import FormMain from "./components/FormMain";

const InvestmentCreate = () => (
  <React.Fragment>
    <Helmet title="Adicionar investimento" />
    <Container fluid className="p-0">
      <Header title="Adicionar investimento" />
      <div className="tab">
        <Tab.Container defaultActiveKey="form-investment">
          <Tab.Content>
            <Tab.Pane eventKey="form-investment">
              <FormMain />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </Container>
  </React.Fragment>
);

export default InvestmentCreate;
