import { ErrorMessage, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, FormGroup, Row } from "react-bootstrap";
import CurrencyInput from "react-currency-input-field";
import { useNavigate } from "react-router-dom";

import { statusCode, statusDefault } from "../../../beans/enumerators";
import { messagesAction, messagesForm } from "../../../beans/messages";
import ErrorMessageCustom from "../../../components/forms/ErrorMessageCustom";
import InputsAddress from "../../../components/forms/InputsAddress";
import Yup from "../../error/Yup";
import { Classification } from "../classification/interfaces";
import { getClassificationAll } from "../classification/services";
import Investment, { InvestmentForm } from "../interfaces/investments";
import { postInvestment, updateInvestment } from "../services/investments";
import { Status } from "../status/interfaces";
import { getStatusAll } from "../status/services";

interface InvestmentFormProps {
  investmentSelected?: Investment;
  setInvestmentSelected?: Function;
}

const FormMain = ({
  investmentSelected,
  setInvestmentSelected,
}: InvestmentFormProps) => {
  const [sent, setSent] = useState(false);
  const navigate = useNavigate();

  const [initialValues, setInitialValues] = useState<InvestmentForm>({
    submit: false,
    investment: {
      investment: "",
      value_goal: "",
      rentability: "",
      minimal_investment: "",
      redemption: "",
      active: false,
      description: "",
      fk_id_classification: 1,
      fk_id_status: "",
    },
    country: "Brasil",
    state: "",
    city: "",
    address: {
      postalCode: "",
      neighborhood: "",
      addressNumber: "",
      line_1: "",
      line_2: "",
    },
  });

  useEffect(() => {
    if (investmentSelected) {
      var redemption;
      if (investmentSelected.investment?.redemption)
        redemption = moment(investmentSelected.investment.redemption).format(
          "YYYY-MM-DD"
        );
      var value_goal;
      if (investmentSelected.investment?.value_goal)
        value_goal = Number(investmentSelected.investment?.value_goal)
          .toFixed(2)
          .toString()
          .replace(".", ",");
      var minimal_investment;
      if (investmentSelected.investment?.minimal_investment)
        minimal_investment = Number(
          investmentSelected.investment?.minimal_investment
        )
          .toFixed(2)
          .toString();

      setInitialValues({
        submit: false,
        ...investmentSelected,
        investment: {
          ...investmentSelected.investment,
          redemption,
          value_goal,
          minimal_investment,
        },
      });
    }
  }, [investmentSelected]);

  const [statusList, setStatusList] = useState<Array<Status>>([]);
  const [classificationList, setClassificationList] = useState<
    Array<Classification>
  >([]);
  useEffect(() => {

    getStatusAll().then((response) => {
      setStatusList(!investmentSelected ? response.filter((item) => item.id_status != statusDefault.liquidado) : response)
    });
    getClassificationAll().then((response) => setClassificationList(response));
  }, [investmentSelected]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={Yup.object().shape({
        investment: Yup.object().shape({
          investment: Yup.string().max(150).required(),
          value_goal: Yup.string().required(),
          rentability: Yup.number().required(),
          minimal_investment: Yup.string().required(),
          redemption: Yup.date().required(),
          description: Yup.string().required(),
          fk_id_classification: Yup.number().required(),
          fk_id_status: Yup.number().required("Selecione um status")

        }),
        city: Yup.string().required(),
        state: Yup.string().required(),
        address: Yup.object().shape({
          postalCode: Yup.string().required(),
          neighborhood: Yup.string().required(),
          addressNumber: Yup.string().required(),
          line_1: Yup.string().required(),
        }),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        let newValues: any = { ...values };
        delete newValues.submit;
        delete newValues.investment.totalInvestors;
        delete newValues.investment.totalInvested;
        delete newValues.address.id_address;
        delete newValues.address.fk_id_city;
        delete newValues.investment.code;
        if (values.investment?.fk_id_status) {
          setErrors({ status: 'This is a dummy procedure error' });
          setSubmitting(false);
        }
        if (values.investment?.value_goal)
          newValues.investment.value_goal = Number(
            values.investment.value_goal.toString().replace(",", ".")
          );
        if (values.investment?.minimal_investment)
          newValues.investment.minimal_investment = Number(
            values.investment.minimal_investment.toString().replace(",", ".")
          );
        if (values.investment?.redemption)
          newValues.investment.redemption = moment(
            values.investment.redemption
          ).toDate();

        var investmentData = new FormData();
        investmentData.append("data", JSON.stringify(newValues));

        let response = !investmentSelected
          ? await postInvestment(investmentData)
          : await updateInvestment(investmentData);

        if (response.status === statusCode.created) {
          navigate("/gerenciar-investimentos/" + response.statusText + "/");
        } else if (response.status === statusCode.ok) {
          if (!!setInvestmentSelected) setInvestmentSelected(newValues);
          setSent(true);
          setTimeout(() => {
            setSent(false);
          }, 5000);
        } else {
          const message: string = response.statusText || messagesForm.error;
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit} >

          <Row>
            <Col xl="2" md="3" sm="3">
              <Form.Group className="mb-3">
                <Form.Label>Código</Form.Label>
                <Form.Control
                  size="lg"
                  name=""
                  value={values.investment?.code}
                  readOnly={true}
                />
              </Form.Group>
            </Col>
            <Col xl="10" md="9" sm="9">
              <Form.Group className="mb-3">
                <Form.Label>Título do investimento</Form.Label>
                <Form.Control
                  size="lg"
                  name="investment.investment"
                  value={values.investment?.investment}
                  readOnly={investmentSelected && values.investment?.fk_id_status == statusDefault.liquidado && true}
                  isInvalid={Boolean(
                    //@ts-ignore
                    touched.investment?.investment &&
                    //@ts-ignore
                    errors.investment?.investment
                  )}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="investment.investment"
                  render={ErrorMessageCustom}
                />
              </Form.Group>
            </Col>
            <Col xl="12">
              <Form.Group className="mb-3">
                <Form.Label>Descrição</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  size="lg"
                  readOnly={values.investment?.fk_id_status == statusDefault.liquidado && true}
                  name="investment.description"
                  value={values.investment?.description}
                  isInvalid={Boolean(
                    //@ts-ignore
                    touched.investment?.description &&
                    //@ts-ignore
                    errors.investment?.description
                  )}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="investment.description"
                  render={ErrorMessageCustom}
                />
              </Form.Group>
            </Col>
            <Col xl="12">
              <h3>Detalhes</h3>
              <hr />
            </Col>
            <Col xl="3" md="4" sm="6">
              <Form.Group className="mb-3">
                <Form.Label>Visualização</Form.Label>
                <Form.Select
                  size="lg"
                  name="investment.active"
                  value={values.investment?.active?.toString()}
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  <option value="false">{messagesAction.inactive}</option>
                  <option value="true">{messagesAction.active}</option>
                </Form.Select>
              </Form.Group>
            </Col>

            <Col xl="3" md="4" sm="6">
              <Form.Group className="mb-3">
                <Form.Label>Status</Form.Label>
                <Form.Select
                  isInvalid={
                    //@ts-ignore
                    errors.investment?.fk_id_status &&
                    //@ts-ignore
                    touched.investment?.fk_id_status
                  }
                  size="lg"
                  name="investment.fk_id_status"
                  value={values.investment?.fk_id_status}
                  disabled={values.investment?.fk_id_status == statusDefault.liquidado && true}
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  <option value="">Selecione</option>
                  {statusList.map((item) => (
                    <option value={item.id_status}>{item.status}</option>
                  ))}
                </Form.Select>
                <ErrorMessage name="investment.fk_id_status" render={ErrorMessageCustom} />
              </Form.Group>
            </Col>

            <Col xl="3" md="4" sm="6">
              <Form.Group className="mb-3">
                <Form.Label>Classificação de risco</Form.Label>
                <Form.Select
                  size="lg"
                  name="investment.fk_id_classification"
                  value={values.investment?.fk_id_classification}
                  disabled={values.investment?.fk_id_status == statusDefault.liquidado && true}
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  {classificationList.map((item) => (
                    <option value={item.id_classification}>
                      {item.acronym}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xl="12">
              <h3>Informações de investimento</h3>
              <hr />
            </Col>
            <Col xl="3" md="4" sm="6">
              <Form.Group className="mb-3">
                <Form.Label>Objetivo R$</Form.Label>
                <Form.Control
                  as={CurrencyInput}
                  decimalSeparator=","
                  groupSeparator="."
                  onValueChange={(value: any, name: any) => {
                    setFieldValue("investment.value_goal", value ? value : "");
                  }}
                  size="lg"
                  readOnly={values.investment?.fk_id_status == statusDefault.liquidado && true}
                  name="investment.value_goal"
                  value={values.investment?.value_goal}
                  isInvalid={Boolean(
                    //@ts-ignore
                    touched.investment?.value_goal &&
                    //@ts-ignore
                    errors.investment?.value_goal
                  )}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  name="investment.value_goal"
                  render={ErrorMessageCustom}
                />
              </Form.Group>
            </Col>
            <Col xl="3" md="4" sm="6">
              <Form.Group className="mb-3">
                <Form.Label>Rentabilidade %</Form.Label>
                <Form.Control
                  size="lg"
                  type="number"
                  name="investment.rentability"
                  value={values.investment?.rentability}
                  readOnly={values.investment?.fk_id_status == statusDefault.liquidado && true}
                  isInvalid={Boolean(
                    //@ts-ignore
                    touched.investment?.rentability &&
                    //@ts-ignore
                    errors.investment?.rentability
                  )}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="investment.rentability"
                  render={ErrorMessageCustom}
                />
              </Form.Group>
            </Col>
            <Col xl="3" md="4" sm="6">
              <Form.Group className="mb-3">
                <Form.Label>Investimento mínimo R$</Form.Label>
                <Form.Control
                  as={CurrencyInput}
                  decimalSeparator=","
                  groupSeparator="."
                  onValueChange={(value: any, name: any) => {
                    setFieldValue(
                      "investment.minimal_investment",
                      value ? value : ""
                    );
                  }}
                  readOnly={values.investment?.fk_id_status == statusDefault.liquidado && true}
                  size="lg"
                  name="investment.minimal_investment"
                  value={values.investment?.minimal_investment}
                  isInvalid={Boolean(
                    //@ts-ignore
                    touched.investment?.minimal_investment &&
                    //@ts-ignore
                    errors.investment?.minimal_investment
                  )}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  name="investment.minimal_investment"
                  render={ErrorMessageCustom}
                />
              </Form.Group>
            </Col>
            <Col xl="3" md="4" sm="6">
              <Form.Group className="mb-3">
                <Form.Label>Data resgate</Form.Label>
                <Form.Control
                  size="lg"
                  type="date"
                  name="investment.redemption"
                  readOnly={values.investment?.fk_id_status == statusDefault.liquidado && true}
                  value={values.investment?.redemption?.toString()}
                  isInvalid={Boolean(
                    //@ts-ignore
                    touched.investment?.redemption &&
                    //@ts-ignore
                    errors.investment?.redemption
                  )}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="investment.redemption"
                  render={ErrorMessageCustom}
                />
              </Form.Group>
            </Col>

            <Col xl="12">
              <h3>Endereço</h3>
              <hr />
            </Col>
          </Row>

          <InputsAddress
            errors={errors}
            setFieldValue={setFieldValue}
            touched={touched}
            handleBlur={handleBlur}
            handleChange={handleChange}
            values={values}
            liquidado={values.investment?.fk_id_status == statusDefault.liquidado && true}
          />

          {errors.submit && (
            <Alert className="mt-4" variant="danger">
              <div className="alert-message">{errors.submit}</div>
            </Alert>
          )}

          {sent && (
            <Alert className="mt-4" variant="success">
              <div className="alert-message">{messagesAction.success}</div>
            </Alert>
          )}

          <div className="mt-4">
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={isSubmitting}
            >
              {messagesAction.save}
            </Button>
          </div>
        </Form>
      )}
    </Formik >
  );
};

export default FormMain;
