import axiosInstance from "../../../utils/axios";
import { verifyDataList, verifyData } from "../../../utils/verify-response";

import Gender from "../interfaces/gender";

export async function getGenderAll(): Promise<Array<Gender>> {
  return axiosInstance.get('/user/gender/').then(response => {
    return verifyDataList(response);
  }).catch(error => {
    return verifyDataList(error);
  });
}

export async function getGender(id: number): Promise<Gender | undefined> {
  return axiosInstance.get('/user/gender/' + id).then(response => {
    return verifyData(response);
  }).catch(error => {
    return verifyData(error);
  });
}