import React from "react";
import { PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";

const ParentInvestments: React.FC<PropsWithChildren> = ({ children }) => (
  <React.Fragment>
    {children}
    <Outlet />
  </React.Fragment>
);

export default ParentInvestments;
