import { ErrorMessage, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import CurrencyInput from "react-currency-input-field";

import { statusCode } from "../../../beans/enumerators";
import { messagesAction, messagesForm } from "../../../beans/messages";
import ErrorMessageCustom from "../../../components/forms/ErrorMessageCustom";
import useToken from "../../../utils/hooks/useToken";
import Yup from "../../error/Yup";
import { InvestForm } from "../interfaces/investments";
import { invest } from "../services/investments";

interface InvestProps {
  show: boolean;
  setModalShow: React.Dispatch<React.SetStateAction<boolean>>;
  screenUpdate?: void;
  idSelected: number;
  maxInvestment: number
}

const ModalInvest = ({
  setModalShow,
  show,
  idSelected,
  maxInvestment,
}: ModalProps | InvestProps) => {

  const [idTimeOut, setIdTimeOut] = useState<any>();
  const [initialValues, setInitialValues] = useState<InvestForm>({
    investment_value: 0,
    investment_uuid_investment: idSelected,
    user_uuid_user: String(useToken().user?.uuid_user),
    submit: false
  });

  const formikSubmit = async (
    values: InvestForm,
    {
      setErrors,
      setStatus,
      setSubmitting,
    }: FormikHelpers<InvestForm>
  ) => {

    if (values.investment_value) {
      values.investment_value = Number(String(values?.investment_value).replace(',', '.'))
    }

    values.investment_uuid_investment = idSelected

    let { submit, ...dataInvest } = values;
    let response = await invest(dataInvest);
    if (
      response.status === statusCode.created ||
      response.status === statusCode.ok
    ) {
      setModalShow(false);
      setStatus(true);
      clearTimeout(idTimeOut);
      setIdTimeOut(
        setTimeout(() => {
          setStatus(false);
        }, 5000)
      );
      // screenUpdate();
    } else {
      const message: string = response.statusText || messagesForm.error;
      setStatus({ success: false });
      setErrors({ submit: message });
      setSubmitting(false);
    }
  };

  function handleClose() {
    setModalShow(false);
  }

  return (
    <Modal
      show={show}
      onHide={() => {
        handleClose();
      }}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={Yup.object({ investment_value: Yup.string().required(messagesForm.required) })}
        validateOnBlur={true}
        onSubmit={formikSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          isSubmitting,
          values,
          status,
          setFieldValue
        }) => (

          <Form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Investir
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row className="justify-content-between">

                <Col xs="12">
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="investment_value">
                      Insira o valor para investir
                    </Form.Label>
                    <Col>
                      <b>Valor disponível para investimento : {maxInvestment}</b>
                    </Col>

                    <Form.Control
                      as={CurrencyInput}
                      size="lg"
                      name="investment_value"
                      id={"investment_value"}
                      value={values.investment_value}
                      isInvalid={Boolean(touched.investment_value && errors.investment_value)}
                      decimalSeparator=","
                      groupSeparator="."

                      onValueChange={(value: any, name: any) => {
                        if (parseFloat(value) > maxInvestment) {
                          setFieldValue('investment_value', maxInvestment)
                        } else {
                          setFieldValue('investment_value', value ? value : '')
                        }

                      }}
                      onBlur={handleBlur}
                    />

                    <ErrorMessage name="investment_value" render={ErrorMessageCustom} />
                  </Form.Group>
                </Col>
              </Row>

              {errors.submit && (
                <Alert className="mt-4" variant="danger">
                  <div className="alert-message">{errors.submit}</div>
                </Alert>
              )}

              {status == true && (
                <Alert className="mt-4" variant="success">
                  <div className="alert-message">
                    {messagesAction.success}
                  </div>
                </Alert>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                {messagesAction.close}
              </Button>
              <Button
                type="submit"
                variant="primary"
                disabled={isSubmitting}
                className="shadow-sm"
              >
                {messagesAction.save}
              </Button>
            </Modal.Footer>
          </Form>

        )}
      </Formik>
    </Modal>
  );
};

export default ModalInvest;
