import { AxiosResponse } from "axios";

import axiosInstance from "../../../utils/axios";
import { verifyData, verifyDataPagination, verifyResponse } from "../../../utils/verify-response";
import { verifyDataArray } from "../../../utils/verify-response";
import Investment, { Invest, InvestmentDetail, ListOfInvestors } from "../interfaces/investments";

interface PaginationResponse {
  list: Array<Investment>;
  total: number;
}

export async function getInvestmentAll(filters?: any): Promise<PaginationResponse> {
  return axiosInstance.get('/investment/?' + new URLSearchParams(filters).toString()).then(response => {
    return verifyDataPagination(response);
  }).catch(error => {
    return verifyDataPagination(error);
  });
}

export async function getInvestment(uuid: string): Promise<Investment | undefined> {
  return axiosInstance.get('/investment/' + uuid).then(response => {
    return verifyData(response);
  }).catch(error => {
    return verifyData(error);
  });
}

export async function postInvestment(investmentData: any): Promise<AxiosResponse> {
  return axiosInstance.post('/investment/', investmentData).then(response => {
    return verifyResponse(response);
  }).catch(error => {
    return verifyResponse(error);
  });
}

export async function updateInvestment(investmentData: any): Promise<AxiosResponse> {
  return axiosInstance.put('/investment/', investmentData).then(response => {
    return verifyResponse(response);
  }).catch(error => {
    return verifyResponse(error);
  });
}

export async function changeStatusInvestment(investmentData: InvestmentDetail): Promise<AxiosResponse> {
  return axiosInstance.put('/investment/changeStatus', investmentData).then(response => {
    return verifyResponse(response);
  }).catch(error => {
    return verifyResponse(error);
  });
}

export async function deleteInvestment(uuid: string): Promise<AxiosResponse> {
  return axiosInstance.delete('/investment/' + uuid).then(response => {
    return verifyResponse(response);
  }).catch(error => {
    return verifyResponse(error);
  });
}

export async function gettAllInvestorsList(uuid: string): Promise<Array<ListOfInvestors> | undefined> {
  return axiosInstance.get('/investment/investor/' + uuid).then(response => {
    return verifyDataArray(response);
  }).catch(error => {
    return verifyDataArray(error);
  });
}

export async function liquidInvestors(objectLiquidation: { uuid_investment: string, rentability?: string }): Promise<AxiosResponse> {
  return axiosInstance.post('/investment/liquid', objectLiquidation).then(response => {
    return verifyResponse(response);
  }).catch(error => {
    return verifyResponse(error);
  });
}

export async function invest(Invest: Invest): Promise<AxiosResponse> {
  return axiosInstance.patch('/investment/invest', Invest).then(response => {
    return verifyResponse(response);
  }).catch(error => {
    return verifyResponse(error);
  });
}

