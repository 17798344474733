import axiosInstance from "../../../utils/axios";
import { verifyDataList, verifyData, verifyResponse } from "../../../utils/verify-response";

import { AxiosResponse } from "axios";
import BankAccount, { BankAccountType, BankInfo } from "./interfaces";

export async function getBankAccount(uuid_user: string): Promise<BankAccount | undefined> {
  return axiosInstance.get('/user/bankAccount/' + uuid_user).then(response => {
    return verifyData(response);
  }).catch(error => {
    return verifyData(error);
  });
}

export async function patchBankAccount(bankData: BankAccount): Promise<AxiosResponse> {
  return axiosInstance.patch('/user/bankAccount/', bankData).then(response => {
    return verifyResponse(response);
  }).catch(error => {
    return verifyResponse(error);
  });
}

export async function getBankAccountTypeAll(): Promise<Array<BankAccountType>> {
  return axiosInstance.get('/user/bankAccount/type/').then(response => {
    return verifyDataList(response);
  }).catch(error => {
    return verifyDataList(error);
  });
}

export async function getBankName(bank_code: string) : Promise<BankInfo> {
    return axiosInstance.get("https://brasilapi.com.br/api/banks/v1/"+bank_code).then(response => {
      if(response && response.data && response.data.name) return { isValid: true, name: response.data.name };
      else return { isValid: false, name: 'Banco inválido' };
    }).catch(error => {
      return { isValid: false, name: 'Banco inválido' };
    });
}