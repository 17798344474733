import React, { useEffect, useState } from "react";
import { Container, Nav, Tab } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";

import Header from "../../components/lists/Header";
import FormImages from "./components/FormImages";
import FormLiquidation from "./components/FormLiquidation";
import FormMain from "./components/FormMain";
import Investment from "./interfaces/investments";
import { getInvestment } from "./services/investments";

const InvestmentUpdate = () => {
  const [investment, setInvestment] = useState<Investment>();
  const navigate = useNavigate();
  const { id } = useParams();
  if (!id) navigate("/gerenciar-investimentos/listar/");

  useEffect(() => {
    if (id)
      getInvestment(id).then((response) => {
        if (!response) {
          navigate("/gerenciar-investimentos/listar/");
        } else {
          setInvestment(response);
        }
      });
  }, []);

  return (
    <React.Fragment>
      <Helmet
        title={"Editar investimento: " + investment?.investment?.investment}
      />
      <Container fluid className="p-0">
        <Header
          title={"Editar investimento: " + investment?.investment?.investment}
        />
        <div className="tab">
          <Tab.Container defaultActiveKey="form-investment">
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="form-investment">Informações</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="form-images">Imagens</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="form-liquidation">Liquidar</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="form-investment">
                <FormMain
                  investmentSelected={investment}
                  setInvestmentSelected={setInvestment}
                />
              </Tab.Pane>
              {!!investment && (
                <Tab.Pane eventKey="form-images">
                  <FormImages
                    investmentSelected={investment}
                    setInvestmentSelected={setInvestment}
                  />
                </Tab.Pane>
              )}
              {!!investment && (
                <Tab.Pane eventKey="form-liquidation">
                  <FormLiquidation
                    investmentSelected={investment}
                    setInvestmentSelected={setInvestment}
                  />
                </Tab.Pane>
              )}
            </Tab.Content>
          </Tab.Container>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default InvestmentUpdate;
