import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";

import ModalInvest from "./components/InvestModal";
import ViewImages from "./components/ViewImages";
import ViewMain from "./components/ViewMain";
import Investment from "./interfaces/investments";
import { getInvestment } from "./services/investments";

const InvestmentRead = () => {
  const [investment, setInvestment] = useState<Investment>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const { id } = useParams();
  if (!id) navigate("/investimentos/listar/");

  useEffect(() => {
    if (id)
      getInvestment(id).then((response) => {
        if (!response) {
          navigate("/investimentos/listar/");
        } else {
          setInvestment(response);
        }
      });
  }, [openModal]);

  return (
    <React.Fragment>
      <Helmet title={investment?.investment?.investment} />
      <Container fluid className="p-0">
        <div className="tab">
          {!!investment && (
            <Card className="p-4">
              <Row>
                {!!investment.investment_image?.length && (
                  <Col lg={6} className="mb-5">
                    <ViewImages images={investment.investment_image} />
                  </Col>
                )}
                <Col xl={5} lg={6} className="mb-5">
                  <ViewMain
                    investmentSelected={investment}
                    setOpenModal={setOpenModal}
                  />
                </Col>
              </Row>
              <div>
                <h3>Descrição</h3>
                {investment.investment?.description}
              </div>
            </Card>
          )}
        </div>
      </Container>

      {id && <ModalInvest idSelected={id} setModalShow={setOpenModal} show={openModal} maxInvestment={Number(investment?.investment?.value_goal) - Number(investment?.investment?.totalInvested)} />}

    </React.Fragment>
  );
};

export default InvestmentRead;