import React, { useState } from "react";
import { Card, Col, Image, Row } from "react-bootstrap";

import { baseURL } from "../../../utils/axios";

interface ImageViewProps {
  images: string[];
}

const ViewImages = ({ images }: ImageViewProps) => {
  const [selectedImage, setSelectedImage] = useState<string>(images[0]);

  const handleImageClick = (image: string) => {
    setSelectedImage(image);
  };

  return (
    <>
      <Card.Img src={baseURL + "/uploads/investment/" + selectedImage} />
      {images.length > 1 && (
        <Row className="mx-n1">
          {images.map((image: any, index) => (
            <Col
              key={index}
              xs="auto"
              onClick={() => handleImageClick(image)}
              className="px-1 mt-2"
            >
              <Image
                src={baseURL + "/uploads/investment/" + image}
                thumbnail
                fluid
                style={{
                  width: "150px",
                  height: "100px",
                  objectFit: "cover",
                }}
              />
            </Col>
          ))}
        </Row>
      )}
    </>
  );
};

export default ViewImages;
