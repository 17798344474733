import React from "react";

interface AvatarProps {
  src?: string;
  big?: boolean;
  name?: string;
  showName?: boolean;
}

const Avatar = ({ src, big, name, showName }: AvatarProps) => {
  return (
    <>
      <img
        src={
          src
            ? src
            : "https://ui-avatars.com/api/?background=0524C8&color=fff" +
              (big ? "&size=100" : "") +
              "&name=" +
              name
        }
        className={
          (big ? "avatar-big" : "avatar") + " img-fluid rounded-circle"
        }
        alt="Usuário"
      />
      {showName && <span className="ps-2 text-dark">{name}</span>}
    </>
  );
};

export default Avatar;
