import { faRocket, faUser, faWallet } from "@fortawesome/free-solid-svg-icons";

import { SidebarItemsType } from "./interfaces/sidebar";

const accountSection = [
  {
    href: "/usuarios/perfil/",
    icon: faUser,
    title: "Perfil",
  },
] as SidebarItemsType[];

const pagesSection = [
  {
    href: "/investimentos",
    icon: faRocket,
    title: "Investimentos",
    children: [
      {
        href: "/investimentos",
        title: "Ver todos"
      },
      {
        href: "/investimentos/investidos",
        title: "Meus investimentos"
      }
    ]
  },
  {
    href: "/carteira",
    icon: faWallet,
    title: "Carteira Digital",
    children: [
      {
        href: "/carteira/depositos",
        title: "Depósitos",
      },
      {
        href: "/carteira/saques",
        title: "Saques",
      },
      {
        href: "/carteira",
        title: "Extrato",
      },
    ],
  },
] as SidebarItemsType[];

const navItems = [
  {
    title: "Investir",
    pages: pagesSection,
  },
  {
    title: "Minha conta",
    pages: accountSection,
  },
];

export default navItems;
