import moment from "moment";
import React, { useEffect, useState } from "react";
import { Card, Container, Table } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

import { itens_per_page } from "../../beans/enumerators";
import Avatar from "../../components/lists/Avatar";
import Header from "../../components/lists/Header";
import PaginationCustom from "../../components/lists/Pagination";
import { baseURL } from "../../utils/axios";
import { formatMoney } from "../../utils/format";
import useToken from "../../utils/hooks/useToken";
import Filter from "./components/Filter";
import { GetAllInvestmentHasUser } from "./interfaces";
import { getAllInvestmentsHasUsers } from "./services";

const UserInvestors = () => {
  const { isAdmin } = useToken();

  const [nList, setNList] = useState<number>(0);
  const [investmentHasUserList, setInvestHasUserList] = useState<
    GetAllInvestmentHasUser["list"]
  >([]);
  const [pagination, setPagination] = useState<number>(0);
  const [filters, setFilters] = useState<any>({
    pagination: "1",
  });

  function verifyFilters(key: string, newValue: string) {
    let newFilters = filters;
    newFilters[key] = newValue;
    if (key !== "pagination") newFilters["pagination"] = "1";
    setFilters(newFilters);
    screenUpdate();
  }

  async function screenUpdate() {
    await getAllInvestmentsHasUsers(filters).then((response) => {
      setInvestHasUserList(response.list);
      setNList(response.total);
      response.total > 0
        ? setPagination(Math.ceil(response.total / itens_per_page.default))
        : setPagination(0);
    });
  }
  useEffect(() => {
    screenUpdate();
  }, []);

  const [openFilters, setOpenFilters] = useState(false);

  return (
    <React.Fragment>
      <Helmet title="Investidores" />
      <Container fluid className="p-0">
        <Header
          title={!!isAdmin ? "Investidores" : "Meus investimentos"}
          verifyFilters={verifyFilters}
          openFilters={openFilters}
          setOpenFilters={setOpenFilters}
        />
        <Filter
          openFilters={openFilters}
          verifyFilters={verifyFilters}
          theFilters={filters}
        />
        <div className="mt-4">{nList} registros</div>
        <Card className="flex-fill w-100 mt-3">
          <Table striped className="my-0">
            <thead>
              <tr>
                {!!isAdmin && <th>Nome do usuário</th>}
                <th className={!!isAdmin ? "text-center" : ""}>Investimento</th>
                <th className="text-center">Total investido</th>
                <th className="text-center">Data do investimento</th>
                <th className="text-center">Status</th>
              </tr>
            </thead>
            <tbody>
              {!!investmentHasUserList &&
                investmentHasUserList.map((item) => (
                  <>
                    <tr>
                      {!!isAdmin && (
                        <td>
                          <Avatar
                            src={
                              item.photo_image
                                ? baseURL + "/uploads/user/" + item.photo_image
                                : ""
                            }
                            name={item.user_name}
                            showName={true}
                          />
                        </td>
                      )}
                      <td className="text-center">{item.investment}</td>
                      <td className="text-center">
                        R${" "}
                        {formatMoney(Number(item.investment_value).toString())}
                      </td>
                      <td className="text-center">
                        {moment(item.investment_date).format(
                          "DD/MM/YYYY - HH:MM"
                        )}
                      </td>
                      <td className="text-center" style={{ color: item.color }}>
                        {item.status}
                      </td>
                    </tr>
                  </>
                ))}
            </tbody>
          </Table>
        </Card>
        <PaginationCustom
          pagination={pagination}
          filter={filters.pagination}
          verifyFilters={verifyFilters}
        />
      </Container>
    </React.Fragment>
  );
};

export default UserInvestors;
