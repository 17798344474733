import axiosInstance from "../../../utils/axios";
import { verifyDataList, verifyData } from "../../../utils/verify-response";

import Nationality from "../interfaces/nationality";

export async function getNationalityAll(): Promise<Array<Nationality>> {
  return axiosInstance.get('/user/nationality/').then(response => {
    return verifyDataList(response);
  }).catch(error => {
    return verifyDataList(error);
  });
}

export async function getNationality(id: number): Promise<Nationality | undefined> {
  return axiosInstance.get('/user/nationality/' + id).then(response => {
    return verifyData(response);
  }).catch(error => {
    return verifyData(error);
  });
}