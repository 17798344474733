import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

import { DashboardContext } from "../../utils/contexts/DashboardContext";
import { formatMoney } from "../../utils/format";
import userToken from "../../utils/hooks/useToken";
import BarChart from "./components/BarChart";
import Investments from "./components/Investments";
import Statistics from "./components/Statistics";
import Wallet from "./components/Wallet";
import { StatisticsItems } from "./interfaces/dashboard";
import { getDashboardStatistics } from './services/dashboard'

const ClientDashboard = () => {

  let useDashboard = useContext(DashboardContext);

  useEffect(() => {
    useDashboard.updateStatistics();
    useDashboard.updateTransactions();
  }, [])



  return (
    <React.Fragment>
      <Statistics statistics={useDashboard.statistics} />
      <Row>
        <Col lg="7" className="d-flex">
          <BarChart />
        </Col>
        <Col lg="5" className="d-flex">
          <Investments />
        </Col>
      </Row>
      <Wallet useDashboard={useDashboard} />
    </React.Fragment>
  );
};

export default ClientDashboard;
