import { useEffect, useState } from "react";
import { Button, Card, FormGroup, Table } from "react-bootstrap";
import styled from "styled-components";

import { statusDefault } from "../../../beans/enumerators";
import { messagesAction } from "../../../beans/messages";
import Scroll from "../../../components/TableScroll";
import TableScroll from "../../../components/TableScroll";
import { formatMoney } from "../../../utils/format";
import Investment, { ListOfInvestors } from "../interfaces/investments";
import { gettAllInvestorsList } from "../services/investments";
import ModalConfirmLiquidation from "./ModalConfirmLiquidation";

interface InvestmentFormProps {
    investmentSelected?: Investment;
    setInvestmentSelected?: Function;
}

const FormLiquidation = ({
    investmentSelected,
    setInvestmentSelected,
}: InvestmentFormProps) => {

    const [listOfInvestors, setListOfInvestors] = useState<ListOfInvestors[]>([]);
    const [modalShow, setModalShow] = useState<boolean>(false);

    useEffect(() => {
        gettAllInvestorsList(String(investmentSelected?.investment?.uuid_investment)).then((response) => {
            if (response) {
                setListOfInvestors(response)
            }
        })

    }, [investmentSelected]);

    function handleButton() {
        setModalShow(true);
    }

    const StyledTable = styled(Table)`
  width: 100%;
  table-layout: fixed;
`;
    return (
        <><FormGroup>
            {listOfInvestors.length ?
                <>
                    <TableScroll head={[<tr>
                        <th>Investidor</th>
                        <th>Valor investido</th>
                        <th>Rendimento</th>
                    </tr>]}
                        body={listOfInvestors.map((item) => {
                            return <tr>
                                <td>{item.user_name}</td>
                                <td>{formatMoney(item.invested.toString())}</td>
                                <td>{formatMoney(item.income.toString())}</td>
                            </tr>

                        })}
                        bottom={[<tr><td colSpan={3}><b>Totais</b></td></tr>,
                        <tr>
                            <td><b>{listOfInvestors[0].totalInvestors}</b></td>
                            <td><b>R$ {formatMoney(listOfInvestors[0].totalInvested.toString())}</b></td>
                            <td><b>R$ {formatMoney(listOfInvestors[0].totalIncome.toString())}</b></td>
                        </tr>]}
                    />

                    {investmentSelected?.investment?.fk_id_status != statusDefault.liquidado ?
                        <>
                            <Button
                                type="submit"
                                variant="info"
                                size="lg"
                                onClick={handleButton}
                            >
                                {messagesAction.liquidate}
                            </Button>
                        </>
                        :
                        <h3>
                            Investimento Liquidado
                        </h3>
                    }

                </>
                :
                <h1>Não há investidores</h1>
            }
        </FormGroup>
            <ModalConfirmLiquidation
                setModalShow={setModalShow}
                show={modalShow}
                investmentSelected={investmentSelected}

            />
        </>

    );
};

export default FormLiquidation;