import React, { createContext, Dispatch, useEffect, useState } from 'react';
import socketio from 'socket.io-client';

import { User } from '../../pages/user/interfaces/user';
import { baseURL } from '../axios';
import useToken from '../hooks/useToken';

//const io = socketio(baseURL); // Substitua pela URL do seu servidor

export interface ListNotifications {
    id_notification: string,
    type: string;
    title: string;
    description: string
    time: string;
    uuid_user: User['uuid_user'];
}

const initialValues = {
    lista: [] as ListNotifications[],
    setUpdate: () => { }, // Função vazia inicialmente
    io: socketio(baseURL)
}

const SocketContext = createContext(initialValues);


function SocketProvider({ children }: { children: React.ReactNode }) {
    const [update, setUpdate] = useState<boolean>(false);
    const [lista, setLista] = useState<Array<ListNotifications>>([]);
    const { user } = useToken();
    const io = socketio(baseURL)

    useEffect(() => {

        io.on("connect_error", () => {
            console.error("socket error!");
            io.close();
        });


    }, [update, io]);

    const updateSocket = () => {
        setUpdate(prevUpdate => !prevUpdate);
    }

    return (
        <SocketContext.Provider
            value={{ lista, setUpdate: updateSocket, io }}>
            {children}
        </SocketContext.Provider>
    );
}



export { SocketProvider, SocketContext };
