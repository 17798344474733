import axiosInstance from "../../../utils/axios";
import { verifyDataList, verifyData } from "../../../utils/verify-response";

import UserType from "../interfaces/user-type";

export async function getUserTypeAll(): Promise<Array<UserType>> {
  return axiosInstance.get('/user/type/').then(response => {
    return verifyDataList(response);
  }).catch(error => {
    return verifyDataList(error);
  });
}

export async function getUserType(id: number): Promise<UserType | undefined> {
  return axiosInstance.get('/user/type/' + id).then(response => {
    return verifyData(response);
  }).catch(error => {
    return verifyData(error);
  });
}