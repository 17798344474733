import axiosInstance from "../../../utils/axios";
import { verifyDataList, verifyResponse } from "../../../utils/verify-response";

import { AxiosResponse } from "axios";
import PixKey, { PixType, PixKeyDetail } from "./interfaces";

export async function getPixKeyAll(uuid_user: string): Promise<Array<PixKeyDetail>> {
  return axiosInstance.get('/user/pix/?uuid_user=' + uuid_user).then(response => {
    return verifyDataList(response);
  }).catch(error => {
    return verifyDataList(error);
  });
}

export async function patchPixKey(pixData: PixKey): Promise<AxiosResponse> {
  return axiosInstance.patch('/user/pix/', pixData).then(response => {
    return verifyResponse(response);
  }).catch(error => {
    return verifyResponse(error);
  });
}

export async function deletePixKey(uuid: string): Promise<AxiosResponse> {
  return axiosInstance.delete('/user/pix/' + uuid).then(response => {
    return verifyResponse(response);
  }).catch(error => {
    return verifyResponse(error);
  });
}

export async function getPixTypeAll(): Promise<Array<PixType>> {
  return axiosInstance.get('/user/pix/type/').then(response => {
    return verifyDataList(response);
  }).catch(error => {
    return verifyDataList(error);
  });
}