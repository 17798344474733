import { Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import InputMask from "react-input-mask";
import * as Yup from "yup";

import { statusCode } from "../../../beans/enumerators";
import { messagesAction, messagesForm } from "../../../beans/messages";
import Gender from "../interfaces/gender";
import MaritalStatus from "../interfaces/marital-status";
import Nationality from "../interfaces/nationality";
import { UserForm, UserDetail } from "../interfaces/user";
import { getGenderAll } from "../services/gender";
import { getMaritalStatusAll } from "../services/marital-status";
import { getNationalityAll } from "../services/nationality";
import { updateUser } from "../services/user";

export interface UserFormProps {
  userSelected: UserDetail;
  setUserSelected: Function;
}

const UserFormPersonal = ({ userSelected, setUserSelected }: UserFormProps) => {
  const [sent, setSent] = useState(false);

  const [initialValues, setInitialValues] = useState<UserForm>({
    submit: false,
  });

  useEffect(() => {
    var birth_date;
    if (userSelected.user?.birth_date)
      birth_date = moment(userSelected.user.birth_date).format("YYYY-MM-DD");
    setInitialValues({
      submit: false,
      login: userSelected.login,
      user: {
        ...userSelected.user,
        birth_date,
      },
    });
  }, [userSelected]);

  const [genderList, setGenderList] = useState<Array<Gender>>([]);
  const [nationalityList, setNationalityList] = useState<Array<Nationality>>(
    []
  );
  const [maritalStatusList, setMaritalStatusList] = useState<
    Array<MaritalStatus>
  >([]);
  useEffect(() => {
    getGenderAll().then((response) => setGenderList(response));
    getNationalityAll().then((response) => setNationalityList(response));
    getMaritalStatusAll().then((response) => setMaritalStatusList(response));
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={Yup.object().shape({
        user: Yup.object().shape({
          document_1: Yup.string().nullable().required(messagesForm.required),
          birth_date:
            userSelected.user?.fk_id_user_type === 1
              ? Yup.string().nullable().required(messagesForm.required)
              : Yup.string().nullable(),
        }),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        if (values.user?.birth_date) {
          let birth_date = moment(values.user.birth_date).toDate();
          values.user.birth_date = birth_date;
        }
        var userData = new FormData();
        userData.append("user", JSON.stringify(values));

        let response = await updateUser(userData);
        if (response.status !== statusCode.ok) {
          const message: string = response.statusText || messagesForm.error;
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        } else {
          setSent(true);
          setTimeout(() => {
            setSent(false);
          }, 5000);
          setUserSelected(values);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xl="3" md="4" sm="6">
              <Form.Group className="mb-3">
                <Form.Label>
                  {userSelected.user?.fk_id_user_type === 2 ? "CNPJ" : "CPF"}
                </Form.Label>
                <Form.Control
                  as={InputMask}
                  mask={
                    userSelected.user?.fk_id_user_type === 2
                      ? "99.999.999/9999-99"
                      : "999.999.999-99"
                  }
                  maskPlaceholder={null}
                  type="tel"
                  size="lg"
                  name="user.document_1"
                  value={values.user?.document_1}
                  isInvalid={Boolean(
                    //@ts-ignore
                    touched.user?.document_1 && errors.user?.document_1
                  )}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {
                  //@ts-ignore
                  !!touched.user?.document_1 && (
                    <Form.Control.Feedback type="invalid">
                      {
                        //@ts-ignore
                        errors.user?.document_1
                      }
                    </Form.Control.Feedback>
                  )
                }
              </Form.Group>
            </Col>
            <Col xl="3" md="4" sm="6">
              <Form.Group className="mb-3">
                <Form.Label>
                  {userSelected.user?.fk_id_user_type === 2 ? "IE" : "RG"}
                </Form.Label>
                <Form.Control
                  as={InputMask}
                  mask="9999999999"
                  maskPlaceholder={null}
                  type="tel"
                  size="lg"
                  name="user.document_2"
                  value={values.user?.document_2}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col xl="3" md="4" sm="6">
              <Form.Group className="mb-3">
                <Form.Label>Órgão emissor</Form.Label>
                <Form.Control
                  type="text"
                  size="lg"
                  name="user.document_2_dispatching"
                  value={values.user?.document_2_dispatching}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            {userSelected.user?.fk_id_user_type === 1 && (
              <>
                <Col xl="3" md="4" sm="6">
                  <Form.Group className="mb-3">
                    <Form.Label>Data de nascimento</Form.Label>
                    <Form.Control
                      type="date"
                      name="user.birth_date"
                      value={values.user?.birth_date?.toString()}
                      size="lg"
                      isInvalid={Boolean(
                        //@ts-ignore
                        touched.user?.birth_date && errors.user?.birth_date
                      )}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {
                      //@ts-ignore
                      !!errors.user?.birth_date && (
                        <Form.Control.Feedback type="invalid">
                          {
                            //@ts-ignore
                            errors.user?.birth_date
                          }
                        </Form.Control.Feedback>
                      )
                    }
                  </Form.Group>
                </Col>
                <Col xl="3" md="4" sm="6">
                  <Form.Group className="mb-3">
                    <Form.Label>Nacionalidade</Form.Label>
                    <Form.Select
                      size="lg"
                      name="user.fk_id_nationality"
                      value={values.user?.fk_id_nationality}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    >
                      <option value="">Selecione</option>
                      {nationalityList.map((item) => (
                        <option value={item.id_nationality}>
                          {item.nationality}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xl="3" md="4" sm="6">
                  <Form.Group className="mb-3">
                    <Form.Label>Estado civil</Form.Label>
                    <Form.Select
                      size="lg"
                      name="user.fk_id_marital_status"
                      value={values.user?.fk_id_marital_status}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    >
                      <option value="">Selecione</option>
                      {maritalStatusList.map((item) => (
                        <option value={item.id_marital_status}>
                          {item.marital}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xl="3" md="4" sm="6">
                  <Form.Group className="mb-3">
                    <Form.Label>Gênero</Form.Label>
                    <Form.Select
                      size="lg"
                      name="user.fk_id_gender"
                      value={values.user?.fk_id_gender}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    >
                      <option value="">Selecione</option>
                      {genderList.map((item) => (
                        <option value={item.id_gender}>{item.gender}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </>
            )}
            <Col sm="12" />

            <Col xl="3" md="4" sm="6">
              <Form.Group className="mb-3">
                <Form.Label>Telefone</Form.Label>
                <Form.Control
                  as={InputMask}
                  mask="(99) 99999999"
                  maskPlaceholder={null}
                  type="tel"
                  size="lg"
                  name="user.telephone"
                  value={values.user?.telephone}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col xl="3" md="4" sm="6">
              <Form.Group className="mb-3">
                <Form.Label>Celular</Form.Label>
                <Form.Control
                  as={InputMask}
                  mask="(99) 999999999"
                  maskPlaceholder={null}
                  type="tel"
                  size="lg"
                  name="user.cellphone"
                  value={values.user?.cellphone}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          {errors.submit && (
            <Alert className="mt-4" variant="danger">
              <div className="alert-message">{errors.submit}</div>
            </Alert>
          )}

          {sent && (
            <Alert className="mt-4" variant="success">
              <div className="alert-message">{messagesAction.success}</div>
            </Alert>
          )}

          <div className="mt-4">
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={isSubmitting}
            >
              {messagesAction.save}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default UserFormPersonal;
