import {
  faPen,
  faPlusCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row, Table } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

import { statusCode } from "../../../beans/enumerators";
import { messagesAction } from "../../../beans/messages";
import PatchClassification from "./components/PatchClassification";
import { Classification } from "./interfaces";
import { deleteClassification, getClassificationAll } from "./services";
import BtnDropdown from "../../../components/lists/BtnDropdown";

const ListClassification = () => {
  const [classifications, setClassifications] = useState<Array<Classification>>(
    []
  );
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [idSelected, setIdSelected] = useState<number | null>(null);

  function screenUpdate() {
    getClassificationAll().then((response) => {
      setClassifications(response);
    });
  }

  useEffect(() => {
    screenUpdate();
  }, []);

  function handleDelete(id?: number | string) {
    if (id) {
      deleteClassification(Number(id)).then((response) => {
        if (response.status !== statusCode.ok) {
          alert(response.statusText);
        } else {
          screenUpdate();
        }
      });
    }
  }

  return (
    <React.Fragment>
      <Helmet title="Gerenciar Classificações de Risco" />
      <Container fluid className="p-0">
        <>
          <Row>
            <Col xs="auto" className="d-none d-sm-block">
              <h1 className="h3">Gerenciar Classificações de Risco</h1>
            </Col>

            <Col xs="auto" className="ms-auto text-end mt-n1">
              <Button
                onClick={() => {
                  setIdSelected(null);
                  setModalShow(true);
                }}
                variant="success"
                className="shadow-sm"
              >
                <FontAwesomeIcon icon={faPlusCircle} /> {messagesAction.create}
              </Button>
            </Col>
          </Row>

          <Card className="flex-fill w-100 mt-3">
            {!!classifications.length && (
              <Table striped className="my-0">
                <thead>
                  <tr>
                    <th>Sigla</th>
                    <th>Descrição</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {classifications.map((item) => (
                    <tr key={item.id_classification}>
                      <td>{item.acronym}</td>
                      <td>{item.description}</td>
                      <td className="text-end mq-actions">
                        <Button
                          onClick={(e) => {
                            setModalShow(true);
                            setIdSelected(Number(item.id_classification));
                          }}
                          variant="primary"
                        >
                          <FontAwesomeIcon icon={faPen} /> {messagesAction.edit}
                        </Button>

                        <BtnDropdown
                          btn={messagesAction.delete}
                          icon={faTimesCircle}
                          title="Excluir"
                          msg="Se a classificação possuir investimentos, ela não será excluída."
                          handleAction={handleDelete}
                          id={item.id_classification}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </Card>

          {modalShow && (
            <PatchClassification
              show={modalShow}
              setModalShow={setModalShow}
              screenUpdate={screenUpdate}
              idSelected={idSelected}
            />
          )}
        </>
      </Container>
    </React.Fragment>
  );
};

export default ListClassification;
