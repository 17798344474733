import jwtDecode from "jwt-decode";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";

import { account_type } from "../../beans/enumerators";
import { StatisticsItems } from "../../pages/Dashboard/interfaces/dashboard";
import { getDashboardStatistics } from "../../pages/Dashboard/services/dashboard";
import {
  ListTransaction,
  ObjectTransaction,
} from "../../pages/wallet/interfaces/transaction";
import { getAllTransactions } from "../../pages/wallet/services/List";
import { TokenState, TokenUser } from "../interfaces/token";
import { getToken, isValidToken } from "../token";

const initialStateOfStatistics: Array<StatisticsItems> = [
  {
    badge: "",
    data: "",
    name: "",
    text: "",
  },
];

export interface ContextDashBoard {
  statistics: StatisticsItems[];
  updateStatistics: () => void;
  updateTransactions: (date?: Date) => void;
  transactionsList: ObjectTransaction[] | undefined;
  selectedDateTran: Date;
  setSelectedDateTran: (date: Date) => void;
}

const initialSelectedDateTran = moment().toDate();

const DashboardContext = React.createContext<ContextDashBoard>({
  statistics: initialStateOfStatistics,
  updateStatistics: () => {},
  updateTransactions: () => {},
  transactionsList: [] as ListTransaction["list"] | undefined,
  selectedDateTran: moment().toDate(),
  setSelectedDateTran: () => {},
});

interface DashboardProviderType {
  children: React.ReactNode;
}

function DashboardProvider({ children }: DashboardProviderType) {
  const [statistics, setStatistics] = useState(initialStateOfStatistics);
  const [transactionsList, setTransactionsList] = useState<
    ListTransaction["list"] | undefined
  >(undefined);

  const updateStatistics = useCallback(() => {
    getDashboardStatistics().then((response) => {
      if (response) {
        setStatistics(response);
      }
    });
  }, []);

  const [selectedDateTran, setSelectedDateTran] = useState<Date>(
    moment().toDate()
  );
  const updateTransactions = useCallback(
    (selectedDateTran?: Date) => {
      getAllTransactions({
        dt_selected: selectedDateTran
          ? selectedDateTran
          : moment().startOf("month").toDate(),
      }).then((response) => {
        if (response) {
          setTransactionsList(response.list);
        }
      });
    },
    [selectedDateTran]
  );

  return (
    <DashboardContext.Provider
      value={{
        statistics,
        updateStatistics,
        transactionsList,
        updateTransactions,
        selectedDateTran,
        setSelectedDateTran,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
}

export { DashboardProvider, DashboardContext };
