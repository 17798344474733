import React from "react";

import logo from "../../../assets/img/logo.png";

const Logo = () => (
  <img
    src={logo}
    alt="Logo"
    className="img-fluid mx-auto d-block d-lg-none mb-5"
  />
);

export default Logo;
