export const THEME_PALETTE_LIGHT = {
  primary: "#004295",
  "primary-dark": "#0b1538",
  "primary-light": "#84aef2",
  secondary: "#ffc33e",
  success: "#4BBF73",
  info: "#1F9BCF",
  warning: "#f0ad4e",
  danger: "#d9534f",
  white: "#fff",
  "gray-100": "#f4f7f9",
  "gray-200": "#e2e8ee",
  "gray-300": "#dee6ed",
  "gray-400": "#ced4da",
  "gray-500": "#adb5bd",
  "gray-600": "#6c757d",
  "gray-700": "#495057",
  "gray-800": "#020202",
  "gray-900": "#212529",
  black: "#000",
};
