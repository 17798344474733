import * as React from "react";
import { Navigate } from "react-router-dom";

import useToken from "../../utils/hooks/useToken";

interface AdminGuardType {
  children: React.ReactNode;
}

// For routes that can only be accessed by authenticated ADMIN users
function AdminGuard({ children }: AdminGuardType) {
  const { isAdmin } = useToken();

  if (!isAdmin) {
    return <Navigate to="/" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default AdminGuard;
