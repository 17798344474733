import { Formik } from "formik";
import React, { useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";

import { statusCode } from "../../../beans/enumerators";
import { messagesAction, messagesForm } from "../../../beans/messages";
import { NewPasswordForm } from "../interfaces/auth";
import { newPasswordApi } from "../services/auth";

function NewPassword() {
  const navigate = useNavigate();
  const { hash } = useParams();
  if (!hash) navigate("/acesso/recuperar-senha/");

  const [sent, setSent] = useState(false);
  const initialValues: NewPasswordForm = { submit: false };

  return sent ? (
    <Alert variant="success">
      <div className="alert-message">
        Senha atualizada com sucesso,{" "}
        <Link to="/acesso/entrar/">acesse sua conta</Link>.
      </div>
    </Alert>
  ) : (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        password: Yup.string().max(50).required(messagesForm.required),
        confirm_password: Yup.string()
          .max(50)
          .required(messagesForm.required)
          .oneOf([Yup.ref("password"), null], messagesForm.password),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        if (hash && values.password) {
          newPasswordApi(hash, values.password).then((response) => {
            if (response.status !== statusCode.ok) {
              const message: string = response.statusText || messagesForm.error;
              setStatus({ success: false });
              setErrors({ submit: message });
              setSubmitting(false);
            } else {
              setSent(true);
            }
          });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Senha</Form.Label>
            <Form.Control
              size="lg"
              type="password"
              name="password"
              value={values.password}
              isInvalid={Boolean(touched.password && errors.password)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.password && (
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Confirmar senha</Form.Label>
            <Form.Control
              size="lg"
              type="password"
              name="confirm_password"
              value={values.confirm_password}
              isInvalid={Boolean(
                touched.confirm_password && errors.confirm_password
              )}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.confirm_password && (
              <Form.Control.Feedback type="invalid">
                {errors.confirm_password}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          {errors.submit && (
            <Alert className="mt-4" variant="danger">
              <div className="alert-message">{errors.submit}</div>
            </Alert>
          )}

          <div className="mt-4">
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={isSubmitting}
            >
              {messagesAction.update} senha
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default NewPassword;
