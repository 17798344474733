import { ErrorMessage, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import CurrencyInput from "react-currency-input-field";

import { statusCode } from "../../../beans/enumerators";
import { messagesAction, messagesForm } from "../../../beans/messages";
import ErrorMessageCustom from "../../../components/forms/ErrorMessageCustom";
import Yup from "../../error/Yup";
import { TransactionForm } from "../interfaces/TransactionForm";
import { depositTransaction } from "../services/List";

interface DepositProps {
  show: boolean;
  setModalShow: React.Dispatch<React.SetStateAction<boolean>>;
  screenUpdate: void;
  idSelected?: number;
  type: number;
  title: string;
  labelMessage: string;
}

const ModalTransaction = ({
  setModalShow,
  show,
  screenUpdate,
  type,
  title,
  labelMessage,
  ...rest
}: ModalProps | DepositProps) => {
  const [idTimeOut, setIdTimeOut] = useState<any>();
  const [initialValues, setInitialValues] = useState<TransactionForm>({
    value: undefined,
    type: type,
    submit: false,
  });

  function handleClose() {
    setModalShow(false);
  }

  const formikSubmit = async (
    values: TransactionForm,
    {
      setErrors,
      setStatus,
      setSubmitting,
      resetForm,
    }: FormikHelpers<TransactionForm>
  ) => {
    if (values.value) {
      values.value = Number(String(values?.value).replace(",", "."));
    }

    let { submit, ...dataStatus } = values;
    let response = await depositTransaction(dataStatus);
    if (
      response.status === statusCode.created ||
      response.status === statusCode.ok
    ) {
      setModalShow(false);
      setStatus(true);
      clearTimeout(idTimeOut);
      setIdTimeOut(
        setTimeout(() => {
          setStatus(false);
        }, 5000)
      );
      screenUpdate();
    } else {
      const message: string = response.statusText || messagesForm.error;
      setStatus({ success: false });
      setErrors({ submit: message });
      setSubmitting(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        handleClose();
      }}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={Yup.object({
          value: Yup.string().required(messagesForm.required),
        })}
        validateOnBlur={true}
        onSubmit={formikSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          isSubmitting,
          values,
          status,
          setFieldValue,
        }) => (
          <>
            <Form onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  {title}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row className="justify-content-between">
                  <Col className="">
                    <h5>
                      <strong className="d-inline-block mb-2">
                        Dados bancários da Kopar
                      </strong>
                    </h5>
                    <div className="mb-3 text-sm">
                      BANCO INTER - 077 <br />
                      Agência: 0001 - Conta: 25840648-8 <br />
                      KOPAR INVESTIMENTOS LTDA <br />
                      <hr />
                      CNPJ: <b>48.594.229/0001-28</b>
                      <br />
                      <b>Pix:</b> CNPJ <br />
                    </div>
                  </Col>

                  <Col xs="12">
                    <Form.Group className="mb-3">
                      <Form.Label htmlFor="value">{labelMessage}</Form.Label>
                      <Form.Control
                        as={CurrencyInput}
                        size="lg"
                        name="value"
                        id={"value"}
                        value={values.value}
                        isInvalid={Boolean(touched.value && errors.value)}
                        decimalSeparator=","
                        groupSeparator="."
                        onValueChange={(value: any, name: any) => {
                          setFieldValue("value", value ? value : "");
                        }}
                        onBlur={handleBlur}
                      />

                      <ErrorMessage name="value" render={ErrorMessageCustom} />
                    </Form.Group>
                  </Col>
                </Row>

                {errors.submit && (
                  <Alert className="mt-4" variant="danger">
                    <div className="alert-message">{errors.submit}</div>
                  </Alert>
                )}

                {status == true && (
                  <Alert className="mt-4" variant="success">
                    <div className="alert-message">
                      {messagesAction.success}
                    </div>
                  </Alert>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  {messagesAction.close}
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  disabled={isSubmitting}
                  className="shadow-sm"
                >
                  {messagesAction.save}
                </Button>
              </Modal.Footer>
            </Form>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default ModalTransaction;
