import { Card, Col, Collapse, Row } from "react-bootstrap";
import CurrencyInput from "react-currency-input-field";

import { situation_type } from "../../../beans/enumerators";
import { TokenState } from "../../../utils/interfaces/token";

interface FilterProps {
  verifyFilters: Function;
  openFilters: boolean;
  theFilters: any;
  token: TokenState;
  transaction_type?: number;
  extract: boolean;
}

const Filter = ({
  verifyFilters,
  openFilters,
  theFilters,
  token,
  transaction_type,
  extract,
}: FilterProps) => {
  return (
    <Collapse in={openFilters} className="mq-filter p-3 pt-0">
      <Card>
        <Row>
          {token.isAdmin && (
            <Col lg="auto" sm="6" className="mt-3">
              <label className="form-label">Nome do usuário</label>
              <input
                type="text"
                className="form-control input-filter"
                onChange={(e) => verifyFilters("user_name", e.target.value)}
              />
            </Col>
          )}
          <Col lg="auto" sm="6" className="mt-3">
            <label className="form-label">Data</label>
            <select
              defaultValue={theFilters.data_period}
              className="form-control form-select input-filter"
              onChange={(e) =>
                verifyFilters("data_period", e.target.value, true)
              }
            >
              <option value="month">Selecione</option>
              <option value="15">15 dias</option>
              <option value="30">30 dias</option>
              <option value="60">60 dias</option>
              <option value="90">90 dias</option>
              <option value="all">Todo o período</option>
            </select>
          </Col>
          {extract && (
            <Col lg="auto" sm="6" className="mt-3">
              <label className="form-label">Tipo de transação</label>
              <select
                defaultValue={theFilters.status}
                className="form-control form-select input-filter"
                onChange={(e) => verifyFilters("type", e.target.value, true)}
              >
                <option value="">Selecione</option>
                <option value={1}>Entrada</option>
                <option value={0}>Saída</option>
              </select>
            </Col>
          )}
          {!extract && (
            <Col lg="auto" sm="6" className="mt-3">
              <label className="form-label">Status</label>
              <select
                defaultValue={theFilters.status}
                className="form-control form-select input-filter"
                onChange={(e) =>
                  verifyFilters("situation", e.target.value, true)
                }
              >
                <option value="">Todos</option>
                <option value={situation_type.analysis}>Pendente</option>
                <option value={situation_type.approved}>Confirmado</option>
                <option value={situation_type.canceled}>Cancelado</option>
                <option value={situation_type.canceledByUser}>
                  Cancelado pelo usuário
                </option>
                {transaction_type === undefined && (
                  <option value={situation_type.invested}>Investido</option>
                )}
              </select>
            </Col>
          )}
          <Col lg="auto" className="mt-3">
            <label className="form-label">Valor: De - Até</label>
            <Row>
              <Col>
                <CurrencyInput
                  className="form-control input-filter"
                  onValueChange={(v) => verifyFilters("value_min", v ? v : "")}
                  decimalSeparator=","
                  groupSeparator="."
                  datatype=""
                  placeholder="De R$"
                />
              </Col>
              <Col>
                <CurrencyInput
                  className="form-control input-filter"
                  onValueChange={(v) => verifyFilters("value_max", v ? v : "")}
                  decimalSeparator=","
                  groupSeparator="."
                  datatype=""
                  placeholder="Até R$"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </Collapse>
  );
};

export default Filter;
