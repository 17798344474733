import moment from "moment";
import { useEffect, useState } from "react";
import { Card, Form, Table } from "react-bootstrap";

import { transaction_type } from "../../../beans/enumerators";
import { ContextDashBoard } from "../../../utils/contexts/DashboardContext";

interface IWallet {
  useDashboard: ContextDashBoard
}

const Wallet = ({ useDashboard: { updateTransactions, transactionsList, selectedDateTran, setSelectedDateTran } }: IWallet) => {

  useEffect(() => {
    if (selectedDateTran) {
      updateTransactions(selectedDateTran);
    } else {
      updateTransactions(moment().startOf('month').toDate());
    }
  }, [selectedDateTran]);

  return (
    <>
      <Card className="flex-fill w-100">
        <Card.Header>
          <div className="card-actions float-end" >
            <Form.Control type="month"
              defaultValue={String(moment(selectedDateTran, 'yyyy-mm').format('yyyy-MM'))} onChange={(e) => { setSelectedDateTran(moment(e.target.value).toDate()) }} />
          </div>
          <Card.Title className="mb-0">Extrato Carteira Digital</Card.Title>
        </Card.Header>
        <Table striped className="my-0">
          <thead>
            <tr>
              <th>Saldo atual</th>
              <th className="d-none d-xl-table-cell"></th>
              <th className="text-end">{transactionsList?.length && Number(transactionsList[0].extract)}</th>
            </tr>
          </thead>
          {transactionsList?.length && transactionsList.map((item) => {
            return (
              <tbody key={item.seq}>
                <tr>
                  <td>{item.investment}</td>
                  <td className="d-none d-xl-table-cell">{moment(item.dt_created).format('DD/MM')}</td>
                  <td className={`text-end ${item.type === transaction_type.in ? 'text-success' : 'text-danger'}`}>{item.type === transaction_type.in ? "+" : "-"} R${" "}
                    {item.value}</td>
                </tr>
              </tbody>
            )
          })}
          <tfoot>
            <tr>
              <th>Saldo anterior</th>
              <th className="d-none d-xl-table-cell"></th>
              <th className="text-end">{transactionsList?.length && transactionsList.at(-1)?.extract}</th>
            </tr>
          </tfoot>
        </Table>
      </Card>
    </>
  )
}

export default Wallet;