import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { account_type, statusCode } from "../../../beans/enumerators";
import { messagesAction, messagesForm } from "../../../beans/messages";
import Avatar from "../../../components/lists/Avatar";
import { baseURL } from "../../../utils/axios";
import Yup from '../../error/Yup';
import { UserForm, UserDetail } from "../interfaces/user";
import UserType from "../interfaces/user-type";
import { postUser, updateUser } from "../services/user";
import { getUserTypeAll } from "../services/user-type";

interface UserFormProps {
  userSelected?: UserDetail;
  setUserSelected?: Function;
}

const UserFormLogin = ({ userSelected, setUserSelected }: UserFormProps) => {
  const navigate = useNavigate();
  const [sent, setSent] = useState(false);

  const [initialValues, setInitialValues] = useState<UserForm>({
    submit: false,
    user: { fk_id_user_type: 1 },
    login: { account_type: account_type.admin },
  });
  const [srcImage, setSrcImage] = useState<string | ArrayBuffer | null>("");

  useEffect(() => {
    if (userSelected)
      setInitialValues({
        submit: false,
        ...userSelected,
      });
    if (userSelected?.user?.photo_image)
      setSrcImage(baseURL + "/uploads/user/" + userSelected.user.photo_image);
  }, [userSelected]);

  const [userTypeList, setUserTypeList] = useState<Array<UserType>>([]);
  useEffect(() => {
    getUserTypeAll().then((response) => setUserTypeList(response));
  }, []);

  const [sendImage, setSendImage] = useState<any>();
  function handleImage(image: FileList | null) {
    if (image) {
      let selectedFile = image[0];
      setSendImage(image[0]);
      var reader = new FileReader();
      reader.onloadend = function () {
        setSrcImage(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setSendImage(null);
    }
  }
  function handleDeleteImage() {
    setSrcImage(null);
    setSendImage(null);
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={Yup.object().shape({
        user: Yup.object().shape({
          user_name: Yup.string().max(150).required(messagesForm.required),
        }),
        login: Yup.object().shape({
          email: Yup.string()
            .email(messagesForm.invalid)
            .max(100)
            .required(messagesForm.required),
          password: !!userSelected
            ? Yup.string().max(50)
            : Yup.string().max(50).required(messagesForm.required),
          confirm_password: !!userSelected
            ? Yup.string()
              .max(50)
              .oneOf([Yup.ref("password"), null], messagesForm.password)
            : Yup.string()
              .max(50)
              .required(messagesForm.required)
              .oneOf([Yup.ref("password"), null], messagesForm.password),
        }),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        delete values.login?.confirm_password;

        if (!srcImage && values.user?.photo_image)
          delete values.user.photo_image;

        var userData = new FormData();
        if (sendImage) userData.append("image-photo", sendImage);
        userData.append("user", JSON.stringify(values));

        let response = !!userSelected
          ? await updateUser(userData)
          : await postUser(values);
        if (response.status !== (statusCode.ok || statusCode.created)) {
          const message: string = response.statusText || messagesForm.error;
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        } else {
          if (!!userSelected && !!setUserSelected) {
            setSent(true);
            setTimeout(() => {
              setSent(false);
            }, 5000);
            setUserSelected(values);
          } else {
            navigate("/usuarios/listar/");
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          {!!userSelected && (
            <Form.Group className="mb-3">
              <Form.Label>Imagem de perfil</Form.Label>
              <div className="d-flex align-items-center mt-2">
                <Avatar
                  name={userSelected?.user?.user_name}
                  big={true}
                  src={srcImage?.toString()}
                />
                <div className="ms-3">
                  <Button variant="primary">
                    <label htmlFor="image_send">Alterar imagem</label>
                  </Button>
                  <input
                    type="file"
                    id="image_send"
                    onChange={(e) => handleImage(e.target.files)}
                    className="d-none"
                  />
                  <div></div>
                  <Button
                    variant="dark"
                    onClick={handleDeleteImage}
                    className="mt-2"
                  >
                    Excluir imagem
                  </Button>
                </div>
              </div>
              <p className="mt-2">
                <small>
                  Lembre-se a imagem precisa ter um tamanho de 100x100px
                </small>
              </p>
            </Form.Group>
          )}
          <Row>
            <Col xl="3" md="4" sm="6">
              <Form.Group className="mb-3">
                <Form.Label>Tipo de usuário</Form.Label>
                <Form.Select
                  size="lg"
                  name="login.account_type"
                  value={values.login?.account_type}
                  disabled={true}
                >
                  <option value={account_type.admin}>Administrador</option>
                  <option value={account_type.client}>Cliente</option>
                </Form.Select>
              </Form.Group>
            </Col>
            {!!userSelected &&
              userSelected.login?.account_type !== account_type.admin && (
                <Col xl="3" md="4" sm="6">
                  <Form.Group className="mb-3">
                    <Form.Label>Tipo de cadastro</Form.Label>
                    <Form.Select
                      size="lg"
                      name="user.fk_id_user_type"
                      value={values.user?.fk_id_user_type}
                      disabled={true}
                    >
                      {userTypeList.map((item) => (
                        <option value={item.id_user_type}>{item.type}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              )}

            <Col xl="3" md="4" sm="6">
              <Form.Group className="mb-3">
                <Form.Label>
                  {!!userSelected && userSelected.user?.fk_id_user_type === 2
                    ? "Razão social"
                    : "Nome completo"}
                </Form.Label>
                <Form.Control
                  size="lg"
                  type="text"
                  name="user.user_name"
                  value={values.user?.user_name}
                  isInvalid={Boolean(
                    //@ts-ignore
                    touched.user?.user_name && errors.user?.user_name
                  )}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {
                  //@ts-ignore
                  !!touched.user?.user_name && (
                    <Form.Control.Feedback type="invalid">
                      {
                        //@ts-ignore
                        errors.user?.user_name
                      }
                    </Form.Control.Feedback>
                  )
                }
              </Form.Group>
            </Col>
            <Col xl="3" md="4" sm="6">
              <Form.Group className="mb-3">
                <Form.Label>
                  {!!userSelected && userSelected.user?.fk_id_user_type === 2
                    ? "Nome fantasia"
                    : "Apelido"}
                </Form.Label>
                <Form.Control
                  size="lg"
                  type="text"
                  name="user.nickname"
                  value={values.user?.nickname}
                  isInvalid={Boolean(
                    //@ts-ignore
                    touched.user?.nickname && errors.user?.nickname
                  )}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {
                  //@ts-ignore
                  !!touched.user?.nickname && (
                    <Form.Control.Feedback type="invalid">
                      {
                        //@ts-ignore
                        errors.user?.nickname
                      }
                    </Form.Control.Feedback>
                  )
                }
              </Form.Group>
            </Col>
            <Col sm="12" />

            <Col xl="3" md="4" sm="6">
              <Form.Group className="mb-3">
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  size="lg"
                  type="email"
                  name="login.email"
                  value={values.login?.email}
                  isInvalid={Boolean(
                    //@ts-ignore
                    touched.login?.email && errors.login?.email
                  )}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {
                  //@ts-ignore
                  !!touched.login?.email && (
                    <Form.Control.Feedback type="invalid">
                      {
                        //@ts-ignore
                        errors.login?.email
                      }
                    </Form.Control.Feedback>
                  )
                }
              </Form.Group>
            </Col>

            <Col xl="3" md="4" sm="6">
              <Form.Group className="mb-3">
                <Form.Label>Senha</Form.Label>
                <Form.Control
                  size="lg"
                  type="password"
                  name="login.password"
                  value={values.login?.password}
                  isInvalid={Boolean(
                    //@ts-ignore
                    touched.login?.password && errors.login?.password
                  )}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {
                  //@ts-ignore
                  !!touched.login?.password && (
                    <Form.Control.Feedback type="invalid">
                      {
                        //@ts-ignore
                        errors.login?.password
                      }
                    </Form.Control.Feedback>
                  )
                }
              </Form.Group>
            </Col>

            <Col xl="3" md="4" sm="6">
              <Form.Group className="mb-3">
                <Form.Label>Confirmar senha</Form.Label>
                <Form.Control
                  size="lg"
                  type="password"
                  name="login.confirm_password"
                  value={values.login?.confirm_password}
                  isInvalid={Boolean(
                    //@ts-ignore
                    touched.login?.confirm_password &&
                    //@ts-ignore
                    errors.login?.confirm_password
                  )}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {
                  //@ts-ignore
                  !!touched.login?.confirm_password && (
                    <Form.Control.Feedback type="invalid">
                      {
                        //@ts-ignore
                        errors.login?.confirm_password
                      }
                    </Form.Control.Feedback>
                  )
                }
              </Form.Group>
            </Col>
          </Row>

          {errors.submit && (
            <Alert className="mt-4" variant="danger">
              <div className="alert-message">{errors.submit}</div>
            </Alert>
          )}

          {sent && (
            <Alert className="mt-4" variant="success">
              <div className="alert-message">{messagesAction.success}</div>
            </Alert>
          )}

          <div className="mt-4">
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={isSubmitting}
            >
              {messagesAction.save}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default UserFormLogin;
