import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Col, Row } from "react-bootstrap";

import NewPassword from "./components/NewPassword";
import Logo from "./components/Logo";
import LogoWhite from "./components/LogoWhite";

const NewPasswordPage = () => (
  <React.Fragment>
    <Helmet title="Nova senha" />
    <Row className="h-100 align-items-center py-4 g-0">
      <LogoWhite />
      <Col lg={6}>
        <Logo />
        <div className="block-max-width mx-auto ps-4 ps-xl-0">
          <div className="text-center">
            <h2>Nova senha</h2>
            <p className="lead">Olá, informe sua nova senha</p>
          </div>
          <NewPassword />
          <small className="mt-4 d-block">
            <Link to="/acesso/entrar/">Voltar</Link>
          </small>
        </div>
      </Col>
    </Row>
  </React.Fragment>
);

export default NewPasswordPage;
