import { AxiosResponse } from "axios";

import axiosInstance from "../../../utils/axios";
import { ListNotifications } from "../../../utils/contexts/SocketContext";
import { verifyDataArray, verifyResponse } from "../../../utils/verify-response";

export async function getNotifications(): Promise<ListNotifications[]> {
    return axiosInstance.get('/others/notification').then(response => {
        return response.data as ListNotifications[];
    }).catch(error => {
        return error;
    });
}

export async function deleteNotification(id: string): Promise<AxiosResponse> {
    return axiosInstance.delete('/others/notification/' + id).then(response => {
        return verifyResponse(response);
    }).catch(error => {
        return verifyResponse(error);
    });
}