import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams, useNavigate } from "react-router-dom";

import { Container, Tab, Nav } from "react-bootstrap";

import Header from "../../components/lists/Header";
import FormLogin from "./components/FormLogin";
import FormPersonal from "./components/FormPersonal";
import FormBank from "./user-bank/FormBank";
import FormAddress from "./user-address/FormAddress";
import FormPix from "./user-pix/FormPix";

import { UserDetail } from "./interfaces/user";
import { getUser } from "./services/user";

import useToken from "../../utils/hooks/useToken";
import { account_type } from "../../beans/enumerators";

const UserUpdate = () => {
  const { user } = useToken();
  const [userSelected, setUserSelected] = useState<UserDetail>();
  const navigate = useNavigate();
  const { id } = useParams();

  function profile() {
    navigate("/usuarios/" + user?.uuid_user + "/");
  }

  function goBack() {
    navigate("/usuarios/listar/");
  }

  useEffect(() => {
    if (id) {
      if (id === "perfil") {
        profile();
      } else if (
        user?.account_type === account_type.client &&
        user.uuid_user !== id
      ) {
        goBack();
      } else {
        getUser(id).then((response) => {
          if (response) {
            setUserSelected(response);
          } else {
            goBack();
          }
        });
      }
    } else {
      goBack();
    }
  }, [id]);

  return (
    <React.Fragment>
      <Helmet title={"Editar usuário: " + userSelected?.user?.user_name} />
      <Container fluid className="p-0">
        <Header title={"Editar usuário: " + userSelected?.user?.user_name} />
        <div className="tab">
          <Tab.Container defaultActiveKey="form-login">
            {userSelected?.login?.account_type !== account_type.admin && (
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="form-login">Acesso</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="form-personal">Pessoal</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="form-bank">Conta bancária</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="form-pix">Chaves PIX</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="form-address">Endereço</Nav.Link>
                </Nav.Item>
              </Nav>
            )}
            <Tab.Content>
              <Tab.Pane eventKey="form-login">
                <FormLogin
                  userSelected={userSelected}
                  setUserSelected={setUserSelected}
                />
              </Tab.Pane>
              {!!userSelected &&
                userSelected?.login?.account_type !== account_type.admin && (
                  <>
                    <Tab.Pane eventKey="form-personal">
                      <FormPersonal
                        userSelected={userSelected}
                        setUserSelected={setUserSelected}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="form-bank">
                      <FormBank
                        userSelected={userSelected}
                        setUserSelected={setUserSelected}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="form-pix">
                      <FormPix
                        userSelected={userSelected}
                        setUserSelected={setUserSelected}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="form-address">
                      <FormAddress
                        userSelected={userSelected}
                        setUserSelected={setUserSelected}
                      />
                    </Tab.Pane>
                  </>
                )}
            </Tab.Content>
          </Tab.Container>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default UserUpdate;
