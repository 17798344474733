import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import InputMask from "react-input-mask";
import * as Yup from "yup";

import { statusCode } from "../../../beans/enumerators";
import { messagesAction, messagesForm } from "../../../beans/messages";
import { UserFormProps } from "../components/FormPersonal";
import { BankAccountForm, BankAccountType } from "./interfaces";
import {
  getBankAccount,
  getBankAccountTypeAll,
  getBankName,
  patchBankAccount,
} from "./services";

const UserFormBank = ({ userSelected, setUserSelected }: UserFormProps) => {
  const [sent, setSent] = useState(false);

  const [bankCode, setBankCode] = useState<string>();
  const [bankName, setBankName] = useState<string>();
  const [bankError, setBankError] = useState<string>();

  const [initialValues, setInitialValues] = useState<BankAccountForm>({
    submit: false,
  });

  useEffect(() => {
    if (userSelected.user?.uuid_user) {
      getBankAccount(userSelected.user.uuid_user).then((response) => {
        setInitialValues({ submit: false, ...response });
        setBankCode(response?.bankName?.code);
        setBankName(response?.bankName?.bank);
      });
    }
  }, [userSelected]);

  const [bankTypeList, setBankTypeList] = useState<Array<BankAccountType>>([]);
  useEffect(() => {
    getBankAccountTypeAll().then((response) => setBankTypeList(response));
  }, []);

  useEffect(() => {
    if (bankCode && bankCode.length === 3) {
      getBankName(bankCode).then((bankInfo) => {
        if (bankInfo.isValid) {
          setBankName(bankInfo.name);
          setBankError(undefined);
        } else {
          setBankName("");
          setBankError(bankInfo.name);
        }
      });
    } else {
      setBankName("");
      setBankError(undefined);
    }
  }, [bankCode]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={Yup.object().shape({
        bankAccount: Yup.object().shape({
          agency: Yup.string().required(messagesForm.required),
          account_number: Yup.string().required(messagesForm.required),
        }),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        if (!bankCode || !bankName) {
          setStatus({ success: false });
          setSubmitting(false);
          setBankError("Banco é obrigatório");
        } else {
          values.bankName = { code: bankCode, bank: bankName };
          if (values.bankAccount && !values.bankAccount.fk_id_bank_account_type)
            values.bankAccount.fk_id_bank_account_type = 1;
          if (values.bankAccount)
            values.bankAccount.fk_uuid_user = userSelected.user?.uuid_user;

          let newValues: any = { ...values };
          delete newValues.submit;

          let response = await patchBankAccount(newValues);

          if (
            response.status !== statusCode.created &&
            response.status !== statusCode.ok
          ) {
            const message: string = response.statusText || messagesForm.error;
            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          } else {
            setSent(true);
            setTimeout(() => {
              setSent(false);
            }, 5000);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xl="2" md="3" sm="4" xs="6">
              <Form.Group className="mb-3">
                <Form.Label>Código banco (3 dígitos)</Form.Label>
                <Form.Control
                  as={InputMask}
                  mask="999"
                  maskPlaceholder={null}
                  type="tel"
                  size="lg"
                  value={bankCode}
                  onChange={(e) =>
                    setBankCode(e.target.value.replace(/\D+/g, ""))
                  }
                />
              </Form.Group>
            </Col>
            <Col xl="3" md="4" sm="6">
              <Form.Group className="mb-3">
                <Form.Label>Nome banco</Form.Label>
                <Form.Control
                  type="text"
                  size="lg"
                  value={bankName}
                  isInvalid={!!bankError}
                  readOnly
                />
                {!!bankError && (
                  <Form.Control.Feedback type="invalid">
                    {bankError}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>

            <Col xl="3" md="4" sm="6">
              <Form.Group className="mb-3">
                <Form.Label>Tipo de conta</Form.Label>
                <Form.Select
                  size="lg"
                  name="bankAccount.fk_id_bank_account_type"
                  value={values.bankAccount?.fk_id_bank_account_type}
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  {bankTypeList.map((item) => (
                    <option value={item.id_bank_account_type}>
                      {item.bank_account_type}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xl="2" md="3" sm="4" xs="6">
              <Form.Group className="mb-3">
                <Form.Label>Agência</Form.Label>
                <Form.Control
                  as={InputMask}
                  mask="9999999"
                  maskPlaceholder={null}
                  type="tel"
                  size="lg"
                  name="bankAccount.agency"
                  value={values.bankAccount?.agency}
                  isInvalid={Boolean(
                    //@ts-ignore
                    touched.bankAccount?.agency && errors.bankAccount?.agency
                  )}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {
                  //@ts-ignore
                  !!touched.bankAccount?.agency && (
                    <Form.Control.Feedback type="invalid">
                      {
                        //@ts-ignore
                        errors.bankAccount?.agency
                      }
                    </Form.Control.Feedback>
                  )
                }
              </Form.Group>
            </Col>
            <Col xl="2" md="3" sm="4" xs="6">
              <Form.Group className="mb-3">
                <Form.Label>Operação</Form.Label>
                <Form.Control
                  as={InputMask}
                  mask="999"
                  maskPlaceholder={null}
                  type="tel"
                  size="lg"
                  name="bankAccount.operation"
                  value={values.bankAccount?.operation}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col sm="12"></Col>
            <Col xl="3" md="4" sm="6">
              <Form.Group className="mb-3">
                <Form.Label>Nº conta</Form.Label>
                <Form.Control
                  as={InputMask}
                  mask="99999999999999999"
                  maskPlaceholder={null}
                  type="tel"
                  size="lg"
                  name="bankAccount.account_number"
                  value={values.bankAccount?.account_number}
                  isInvalid={Boolean(
                    //@ts-ignore
                    touched.bankAccount?.account_number &&
                      //@ts-ignore
                      errors.bankAccount?.account_number
                  )}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {
                  //@ts-ignore
                  !!touched.bankAccount?.account_number && (
                    <Form.Control.Feedback type="invalid">
                      {
                        //@ts-ignore
                        errors.bankAccount?.account_number
                      }
                    </Form.Control.Feedback>
                  )
                }
              </Form.Group>
            </Col>
            <Col xl="2" md="3" sm="4" xs="6">
              <Form.Group className="mb-3">
                <Form.Label>Dígito</Form.Label>
                <Form.Control
                  as={InputMask}
                  mask="99"
                  maskPlaceholder={null}
                  type="tel"
                  size="lg"
                  name="bankAccount.verify_digit"
                  value={values.bankAccount?.verify_digit}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          {errors.submit && (
            <Alert className="mt-4" variant="danger">
              <div className="alert-message">{errors.submit}</div>
            </Alert>
          )}

          {sent && (
            <Alert className="mt-4" variant="success">
              <div className="alert-message">{messagesAction.success}</div>
            </Alert>
          )}

          <div className="mt-4">
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={isSubmitting}
            >
              {messagesAction.save}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default UserFormBank;
