import React, { useEffect, useState } from "react";
import { Badge, Card, Table } from "react-bootstrap";
import { Link } from "react-router-dom";

import Avatar from "../../../components/lists/Avatar";
import { baseURL } from "../../../utils/axios";
import { InvestmentStatusDisplay } from "../interfaces/dashboard";
import { getLastFourInvestments } from "../services/dashboard";

const Investments = () => {

  const [lastFourInvestments, setLastFourInvestments] = useState<Array<InvestmentStatusDisplay>>()

  useEffect(() => {
    getLastFourInvestments().then((response) => {
      if (response) {
        setLastFourInvestments(response);
      }
    });
  }, [])


  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        <Card.Title className="mb-0">Meus Investimentos</Card.Title>
      </Card.Header>
      <Card.Body className="d-flex py-0">

        <Table striped className="my-0">
          <thead>
            <tr>
              <th className="col-2"></th>
              <th className="col-8">Imóvel</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {lastFourInvestments?.map((item, key) => (
              <tr key={key}>
                <td className="col-2"> <Avatar
                  src={
                    item.image_name
                      ? baseURL +
                      "/uploads/investment/" +
                      item.image_name
                      : ""
                  }
                  showName={true}
                /></td>
                <td className="col-8">
                  {String(item.investment) || ''}</td>
                <td>
                  <Badge style={{ background: String(item.color) }}>{String(item.status)}</Badge>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>


      </Card.Body>
      <Card.Footer>
        <Link to={'/investimentos/investidos'}>
          <span className="d-block text-muted text-center">Ver todos</span>
        </Link>

      </Card.Footer>
    </Card>
  )
};

export default Investments;
