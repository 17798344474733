import React from "react";
import { Helmet } from "react-helmet-async";
import { Container } from "react-bootstrap";

import useToken from "../../utils/hooks/useToken";

import Header from "./components/Header";
import AdminDashboard from "./AdminDashboard";
import ClientDashboard from "./ClientDashboard";

const Dashboard = () => {
  const { isAdmin } = useToken();

  return (
    <React.Fragment>
      <Helmet title="Dashboard" />
      <Container fluid className="p-0">
        <Header />
        {isAdmin ? <AdminDashboard /> : <ClientDashboard />}
      </Container>
    </React.Fragment>
  );
};

export default Dashboard;
