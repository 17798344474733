import React from "react";

import AdminGuard from "./components/guards/AdminGuard";
// Guards
import AuthGuard from "./components/guards/AuthGuard";
import ClientGuard from "./components/guards/ClientGuard";
import GuestGuard from "./components/guards/GuestGuard";
// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import NewPassword from "./pages/auth/NewPassword";
import ResetPassword from "./pages/auth/ResetPassword";
// Auth
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
// Pages
import Dashboard from "./pages/Dashboard";
import Page404 from "./pages/error/Page404";
import Page500 from "./pages/error/Page500";
import InvestmentClassification from "./pages/investments/classification/ListClassification";
import Investments from "./pages/investments/components/Parent";
import InvestmentCreate from "./pages/investments/Create";
import InvestmentsList from "./pages/investments/ListAll";
import InvestmentRead from "./pages/investments/Read";
import InvestmentStatus from "./pages/investments/status/ListStatus";
import InvestmentUpdate from "./pages/investments/Update";
import UserInvestors from "./pages/user-investors";
import UserCreate from "./pages/user/Create";
import UserList from "./pages/user/ListAll";
import UserUpdate from "./pages/user/Update";
import Wallet from "./pages/wallet/components/Parent";
import WalletList from "./pages/wallet/List";
import WalletListIn from "./pages/wallet/ListIn";
import WalletListOut from "./pages/wallet/ListOut";

const routes = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
      {
        path: "investimentos",
        element: (
          <ClientGuard>
            <Investments />
          </ClientGuard>
        ),
        children: [
          {
            path: "",
            element: <InvestmentsList />,
          },
          {
            path: ":id",
            element: <InvestmentRead />,
          },
          {
            path: "investidos",
            element: <UserInvestors />,
          },
        ],
      },
      {
        path: "gerenciar-investimentos",
        element: (
          <AdminGuard>
            <Investments />
          </AdminGuard>
        ),
        children: [
          {
            path: "listar",
            element: <InvestmentsList />,
          },
          {
            path: "adicionar",
            element: <InvestmentCreate />,
          },
          {
            path: "status",
            element: <InvestmentStatus />,
          },
          {
            path: "classificacao",
            element: <InvestmentClassification />,
          },
          {
            path: ":id",
            element: <InvestmentUpdate />,
          },
        ],
      },
      {
        path: "investidores",
        element: (
          <AdminGuard>
            <UserInvestors />
          </AdminGuard>
        ),
      },
      {
        path: "carteira",
        element: <Wallet />,
        children: [
          {
            path: "",
            element: <WalletList />,
          },
          {
            path: "depositos",
            element: <WalletListIn />,
          },
          {
            path: "saques",
            element: <WalletListOut />,
          },
        ],
      },
      {
        path: "usuarios/listar",
        element: (
          <AdminGuard>
            <UserList />
          </AdminGuard>
        ),
      },
      {
        path: "usuarios/adicionar",
        element: (
          <AdminGuard>
            <UserCreate />
          </AdminGuard>
        ),
      },
      {
        path: "usuarios/:id",
        element: <UserUpdate />,
      },
    ],
  },
  {
    path: "acesso",
    element: (
      <GuestGuard>
        <AuthLayout />
      </GuestGuard>
    ),
    children: [
      {
        path: "entrar",
        element: <SignIn />,
      },
      {
        path: "cadastrar",
        element: <SignUp />,
      },
      {
        path: "recuperar-senha",
        element: <ResetPassword />,
      },
      {
        path: "nova-senha/:hash",
        element: <NewPassword />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
