import { Formik, FormikHelpers } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { Alert, Col, Form, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal, { ModalProps } from 'react-bootstrap/Modal';

import { statusCode } from '../../../../beans/enumerators';
import { messagesAction, messagesForm } from '../../../../beans/messages';
import Yup from '../../../error/Yup';
import ptbr from '../../../error/Yup';
import { StatusForm } from '../../status/interfaces';
import { Classification, ClassificationForm } from '../interfaces';
import { getClassification, patchClassification } from '../services';

interface ClassificationProps {
    show: boolean,
    setModalShow: React.Dispatch<React.SetStateAction<boolean>>,
    screenUpdate: void,
    idSelected?: number,
}


const PatchClassification = ({ setModalShow, show, screenUpdate, idSelected, ...rest }: ModalProps | ClassificationProps) => {


    const [idTimeOut, setIdTimeOut] = useState<any>();
    const [initialValues, setInitialValues] = useState<ClassificationForm>({
        acronym: '',
        description: '',
        submit: false,
    });

    function handleClose() {
        if (!idSelected) {
            setInitialValues({ submit: false });
        }
        setModalShow(false);
    };


    useEffect(() => {
        if (idSelected) {
            getClassification(idSelected).then((response) => {
                setInitialValues({ ...response, submit: false })
            })
        }
    }, [idSelected]);


    const formikSubmit = async (values: ClassificationForm, { setErrors, setStatus, setSubmitting, resetForm }: FormikHelpers<ClassificationForm>) => {

        let { submit, ...dataClassification } = values;
        let response = await patchClassification(dataClassification);

        if (response.status === statusCode.created || response.status === statusCode.ok) {
            if (!idSelected) {
                resetForm({
                    values: initialValues
                });
            }
            setStatus(true);
            clearTimeout(idTimeOut);
            setIdTimeOut(setTimeout(() => {
                setStatus(false);
            }, 5000));
            screenUpdate();
        } else {
            const message: string = response.statusText || messagesForm.error;
            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
        }
    }




    return (
        <Modal
            show={show}
            onHide={() => { handleClose() }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={Yup.object().shape<Record<keyof Classification, Yup.AnySchema>>({
                    acronym: Yup.string().min(1).max(10).required(messagesForm.required),
                    description: Yup.string().min(1).max(100).required(messagesForm.required),
                    id_classification: Yup.number().notRequired(),
                })}
                validateOnBlur={false}
                onSubmit={formikSubmit}

            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    isSubmitting,
                    values,
                    status
                }) => (

                    <>
                        <Form onSubmit={handleSubmit}>
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">

                                    {idSelected ? 'Editar' : 'Cadastrar nova'} classificação
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>


                                <Row>
                                    <Col xl="2">
                                        <Form.Group className="mb-2">
                                            <Form.Label>Sigla</Form.Label>
                                            <Form.Control
                                                type="text"
                                                size="lg"
                                                name="acronym"
                                                value={values.acronym}
                                                isInvalid={Boolean(touched.acronym && errors.acronym)}
                                                onBlur={handleBlur}
                                                onChange={handleChange}

                                            />
                                            {!!errors.acronym && (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.acronym}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col xl="10">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Descrição</Form.Label>
                                            <Form.Control
                                                type="text"
                                                size="lg"
                                                as={'textarea'}
                                                rows={1}
                                                name="description"
                                                value={values.description}
                                                onBlur={handleBlur}
                                                isInvalid={Boolean(touched.description && errors.description)}
                                                onChange={handleChange}
                                            />
                                            {!!errors.description && (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.description}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>

                                {errors.submit && (
                                    <Alert className="mt-4" variant="danger">
                                        <div className="alert-message">{errors.submit}</div>
                                    </Alert>
                                )}

                                {status && (
                                    <Alert className="mt-4" variant="success">
                                        <div className="alert-message">{messagesAction.success}</div>
                                    </Alert>
                                )}



                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    {messagesAction.close}
                                </Button>
                                <Button type="submit" variant="primary" disabled={isSubmitting} className="shadow-sm">
                                    {messagesAction.save}
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </>
                )}

            </Formik>
        </Modal>
    );
}

export default PatchClassification;