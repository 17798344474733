import React, { ReactNode } from "react";
import { Row, Col, Button } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { Search, Filter } from "react-feather";
import { messagesAction } from "../../beans/messages";

interface HeaderProps {
  title: string;
  verifyFilters?: Function;
  filterName?: string;
  openFilters?: boolean;
  setOpenFilters?: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenModalAdd?: React.Dispatch<React.SetStateAction<boolean>>;
  children?: ReactNode;
}

const Header = ({
  title,
  verifyFilters,
  filterName,
  openFilters,
  setOpenFilters,
  setOpenModalAdd,
  children,
}: HeaderProps) => {
  /*
  function removeFilter() {
    if (verifyFilters) {
      verifyFilters("uuid_company", "", true);
      verifyFilters("id_task_type", "");
    }
    setOpenFilters(false);
  }*/

  return (
    <Row className="align-items-center mb-3">
      <Col className="mb-2">
        <h1 className="h3 mb-0">{title}</h1>
      </Col>
      <Col sm="auto" className="mb-2">
        <Row className="gx-3">
          {!!verifyFilters && !!filterName && (
            <Col xm="auto">
              <div className="position-relative input-filter">
                <input
                  type="text"
                  placeholder="Pesquisar por nome"
                  className="form-control"
                  onChange={(e) => verifyFilters(filterName, e.target.value)}
                />
                <Search className="absolute-top" />
              </div>
            </Col>
          )}
          {!!setOpenFilters && (
            <Col xm="auto">
              <Button
                variant="primary"
                className="shadow-sm"
                onClick={() => setOpenFilters(!openFilters)}
              >
                <Filter size={16} /> Filtros
              </Button>
            </Col>
          )}
          {!!setOpenModalAdd && (
            <Col xm="auto">
              <Button
                variant="success"
                className="shadow-sm"
                onClick={() => setOpenModalAdd(true)}
              >
                <FontAwesomeIcon icon={faPlusCircle} /> {messagesAction.create}
              </Button>
            </Col>
          )}
          <React.Fragment>{children}</React.Fragment>
        </Row>
      </Col>
    </Row>
  );
};

export default Header;
