import jwtDecode from "jwt-decode";
import moment from "moment";

import axios from "./axios";

const getToken = () => {
  return localStorage.getItem("accessToken");
};

const isValidToken = (accessToken: string | null) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode<{ exp: number }>(accessToken);
  const currentTime = moment().unix();

  return decoded.exp > currentTime;
};

const handleTokenExpired = (exp: number) => {
  let expiredTimer;
  window.clearTimeout(expiredTimer);

  const currentTime = moment().unix();
  const timeLeft = (exp - currentTime) * 1000;

  expiredTimer = window.setTimeout(() => {
    unsetSession();
  }, timeLeft);
};

const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    const { exp } = jwtDecode<{ exp: number }>(accessToken);
    handleTokenExpired(exp);
  } else {
    unsetSession();
  }
};

const unsetSession = () => {
  localStorage.removeItem("accessToken");
  delete axios.defaults.headers.common.Authorization;
  window.location.reload();
};

export { getToken, isValidToken, setSession, unsetSession };