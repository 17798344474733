import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Dropdown,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import InputMask from "react-input-mask";
import * as Yup from "yup";

import { statusCode } from "../../../beans/enumerators";
import { messagesAction, messagesForm } from "../../../beans/messages";
import { UserFormProps } from "../components/FormPersonal";
import { PixKeyDetail, PixKeyForm, PixType } from "./interfaces";
import {
  deletePixKey,
  getPixKeyAll,
  getPixTypeAll,
  patchPixKey,
} from "./services";

const UserFormPix = ({ userSelected, setUserSelected }: UserFormProps) => {
  const [sent, setSent] = useState(false);

  const [initialValues, setInitialValues] = useState<PixKeyForm>({
    submit: false,
  });

  const [pixKeyList, setPixKeyList] = useState<Array<PixKeyDetail>>([]);
  function updateScreen() {
    if (userSelected.user?.uuid_user) {
      getPixKeyAll(userSelected.user.uuid_user).then((response) => {
        setPixKeyList(response);
      });
    }
  }

  const [pixTypeList, setPixTypeList] = useState<Array<PixType>>([]);
  useEffect(() => {
    getPixTypeAll().then((response) => setPixTypeList(response));
    updateScreen();
  }, []);

  function handleDelete(uuid?: string) {
    if (uuid) {
      deletePixKey(uuid).then(() => updateScreen());
    }
  }

  return (
    <>
      {!!pixKeyList.length && (
        <>
          <p>
            <b>Chaves PIX cadastradas</b>
          </p>
          <Table striped className="mb-4 mq-table-small">
            <thead>
              <tr>
                <th>Tipo de chave</th>
                <th>Valor</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {pixKeyList.map((item) => (
                <tr>
                  <td>{item.type_pix?.type_pix}</td>
                  <td className="word-break">{item.pix_key?.key}</td>
                  <td className="mq-actions">
                    <Dropdown className="d-inline-block" align="end">
                      <Dropdown.Toggle variant="danger" className="ms-1">
                        <FontAwesomeIcon icon={faTimesCircle} />
                        <span className="d-none d-xl-inline-block ps-1">
                          {messagesAction.delete}
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.ItemText>Excluir</Dropdown.ItemText>
                        <Dropdown.Divider></Dropdown.Divider>
                        <Dropdown.ItemText>
                          <span>
                            Tem certeza que deseja excluir a chave pix?
                          </span>
                          <Button
                            variant="danger"
                            className="w-100 mt-3"
                            onClick={() =>
                              handleDelete(item.pix_key?.uuid_pix_key)
                            }
                          >
                            Excluir
                          </Button>
                        </Dropdown.ItemText>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
      <p>
        <b>Adicionar chave PIX</b>
      </p>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          fk_id_type_pix: Yup.number().required(messagesForm.required),
          key: Yup.string().required(messagesForm.required),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          values.fk_uuid_user = userSelected.user?.uuid_user;
          let { submit, ...dataPix } = values;
          let response = await patchPixKey(dataPix);
          if (response.status !== statusCode.created) {
            const message: string = response.statusText || messagesForm.error;
            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          } else {
            setSent(true);
            setTimeout(() => {
              setSent(false);
            }, 5000);
            updateScreen();
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xl="3" md="4" sm="6">
                <Form.Group className="mb-3">
                  <Form.Label>Tipo de chave</Form.Label>
                  <Form.Select
                    size="lg"
                    name="fk_id_type_pix"
                    value={values.fk_id_type_pix}
                    isInvalid={Boolean(
                      touched.fk_id_type_pix && errors.fk_id_type_pix
                    )}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  >
                    <option value="">Selecione</option>
                    {pixTypeList.map((item) => (
                      <option value={item.id_type_pix}>{item.type_pix}</option>
                    ))}
                  </Form.Select>
                  {!!touched.fk_id_type_pix && (
                    <Form.Control.Feedback type="invalid">
                      {errors.fk_id_type_pix}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col xl="3" md="4" sm="6">
                <Form.Group className="mb-3">
                  <Form.Label>Valor</Form.Label>
                  <Form.Control
                    type="text"
                    size="lg"
                    name="key"
                    value={values.key}
                    isInvalid={Boolean(errors.key)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {!!errors.key && (
                    <Form.Control.Feedback type="invalid">
                      {errors.key}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col xl="3" md="4" sm="6">
                <div className="mt-4">
                  <Button
                    type="submit"
                    variant="primary"
                    size="lg"
                    disabled={isSubmitting}
                  >
                    {messagesAction.create}
                  </Button>
                </div>
              </Col>
            </Row>

            {errors.submit && (
              <Alert className="mt-4" variant="danger">
                <div className="alert-message">{errors.submit}</div>
              </Alert>
            )}

            {sent && (
              <Alert className="mt-4" variant="success">
                <div className="alert-message">{messagesAction.success}</div>
              </Alert>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UserFormPix;
