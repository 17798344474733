import { AxiosResponse } from "axios";

import { getToken, unsetSession } from './token';

export function verifyDataList(response: any): Array<object> {
    verifyStatusCode(response.status);
    if (!response || !response.data) return [];
    if (response.data[0]) return response.data;
    return [];
}

interface PaginationResponse {
    list: Array<any>;
    total: number;
}
export function verifyDataPagination(response: any): PaginationResponse {
    verifyStatusCode(response.status);
    if (!response || !response.data || !response.data.list) return { list: [], total: 0 };
    if (response.data.list) return response.data;
    return { list: [], total: 0 };
}

export function verifyData(response: any): object | undefined {
    verifyStatusCode(response.status);
    if (!response || !response.data) return undefined;
    if (typeof response.data === 'object') return response.data;
    return undefined;
}

export function verifyDataArray(response: any): Array<any> | undefined {
    verifyStatusCode(response.status);
    if (!response || !response.data) return undefined;
    if (typeof response.data === 'object') return response.data;
    return undefined;
}

export function verifyResponse(response: AxiosResponse): AxiosResponse {
    verifyStatusCode(response.status);
    if (response.data && response.data.status) return response.data;
    return response;
}

async function verifyStatusCode(code: number) {
    if (code === 401 && getToken()) unsetSession();
}