import React from "react";
import { Card, Col, Row } from "react-bootstrap";

import useToken from "../../../utils/hooks/useToken";
import background from "../assets/img/bg-cta.svg";
import app from "../assets/img/mobile.png";
import welcome from "../assets/img/tablet.png";

const Header = () => {
  const { user } = useToken();

  return (
    <React.Fragment>
      <div className="mb-3">
        <h3>Dashboard</h3>
      </div>
      <Row>
        <Col md="6" className="d-flex">
          <Card className="illustration flex-fill">
            <Card.Body className="p-0 d-flex flex-fill">
              <Row className="g-0 w-100">
                <Col xs="6">
                  <div className="illustration-text p-3 m-1">
                    <h4 className="illustration-text">
                      Bem-vindo(a) de volta, {user?.nickname}!
                    </h4>
                    <p className="mb-0">Sua plataforma de investimentos</p>
                  </div>
                </Col>
                <Col xs={6} className="align-self-end text-end">
                  <img
                    src={welcome}
                    alt="Bem-vindo"
                    className="img-fluid illustration-img"
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col md="6" className="d-flex">
          <Card className="illustration-app flex-fill">
            <Card.Body className="p-0 d-flex flex-fill">
              <img
                src={background}
                alt="fundo"
                className="absolute-top w-100 h-100 illustration-bg"
              />
              <Row className="g-0 w-100 position-relative">
                <Col xs="6">
                  <div className="illustration-text p-3 m-1">
                    <h4 className="illustration-text text-white">
                      Comece a usar nosso app e invista <br />
                      de qualquer lugar!
                    </h4>
                    <a
                      href={process.env.REACT_APP_WEBSITE_EXTERNAL}
                      className="btn btn-yellow"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Download
                    </a>
                  </div>
                </Col>
                <Col xs={6} className="align-self-end text-end">
                  <img
                    src={app}
                    alt="app"
                    className="img-fluid illustration-img"
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Header;
