import React, { useEffect, useState } from "react";
import { Card, Col, Collapse, Row } from "react-bootstrap";
import CurrencyInput from "react-currency-input-field";

import useToken from "../../../utils/hooks/useToken";
import { Classification } from "../classification/interfaces";
import { getClassificationAll } from "../classification/services";
import { Status } from "../status/interfaces";
import { getStatusAll } from "../status/services";

interface FilterProps {
  verifyFilters: Function;
  openFilters: boolean;
  theFilters: any;
}

const Filter = ({ verifyFilters, openFilters, theFilters }: FilterProps) => {
  const { isAdmin } = useToken();

  const [status, setStatus] = useState<Array<Status>>([{}]);
  const [classification, setClassification] = useState<Array<Classification>>([
    {},
  ]);

  useEffect(() => {
    getStatusAll().then((response) => {
      setStatus(response);
    });

    getClassificationAll().then((response) => {
      setClassification(response);
    });
  }, []);

  return (
    <Collapse in={openFilters} className="mq-filter p-3 pt-0">
      <Card>
        <Row>
          {isAdmin && (
            <Col lg="auto" sm="6" className="mt-3">
              <label className="form-label">Ativo</label>
              <select
                defaultValue={theFilters.account_type}
                className="form-control form-select input-filter"
                onChange={(e) => verifyFilters("active", e.target.value, true)}
              >
                <option value="">Todos</option>
                <option value="true">Ativos</option>
                <option value="false">Inativos</option>
              </select>
            </Col>
          )}

          <Col lg="auto" sm="6" className="mt-3">
            <label className="form-label">Status</label>
            <select
              className="form-control form-select input-filter"
              onChange={(e) =>
                verifyFilters("fk_id_status", e.target.value, true)
              }
            >
              <option value="">Todos</option>
              {status.map((item) => {
                return <option value={item.id_status}>{item.status}</option>;
              })}
            </select>
          </Col>
          <Col lg="auto" sm="6" className="mt-3">
            <label className="form-label">Classificação</label>
            <select
              className="form-control form-select input-filter"
              onChange={(e) =>
                verifyFilters("fk_id_classification", e.target.value, true)
              }
            >
              <option value="">Todos</option>
              {classification.map((item) => {
                return (
                  <option value={item.id_classification}>
                    {item.description}
                  </option>
                );
              })}
            </select>
          </Col>

          <Col lg="auto" className="mt-3">
            <label className="form-label">Rentabilidade: De - Até</label>
            <Row>
              <Col>
                <CurrencyInput
                  className="form-control input-filter"
                  onValueChange={(v) =>
                    verifyFilters("rentability_from", v ? v : "")
                  }
                  decimalSeparator=","
                  groupSeparator="."
                  datatype=""
                  placeholder="De %"
                />
              </Col>
              <Col>
                <CurrencyInput
                  className="form-control input-filter"
                  onValueChange={(v) =>
                    verifyFilters("rentability_to", v ? v : "")
                  }
                  decimalSeparator=","
                  groupSeparator="."
                  datatype=""
                  placeholder="Até %"
                />
              </Col>
            </Row>
          </Col>

          <Col lg="auto" className="mt-3">
            <label className="form-label">Investimento mínimo: De - Até</label>
            <Row>
              <Col>
                <CurrencyInput
                  className="form-control input-filter"
                  onValueChange={(v) =>
                    verifyFilters("minimal_investment_from", v ? v : "")
                  }
                  decimalSeparator=","
                  groupSeparator="."
                  datatype=""
                  placeholder="De R$"
                />
              </Col>
              <Col>
                <CurrencyInput
                  className="form-control input-filter"
                  onValueChange={(v) =>
                    verifyFilters("minimal_investment_to", v ? v : "")
                  }
                  decimalSeparator=","
                  groupSeparator="."
                  datatype=""
                  placeholder="Até R$"
                />
              </Col>
            </Row>
          </Col>
          <Col lg="auto" className="mt-3">
            <label className="form-label">Valor objetivo: De - Até</label>
            <Row>
              <Col>
                <CurrencyInput
                  className="form-control input-filter"
                  onValueChange={(v) =>
                    verifyFilters("value_goal_from", v ? v : "")
                  }
                  decimalSeparator=","
                  groupSeparator="."
                  datatype=""
                  placeholder="De R$"
                />
              </Col>
              <Col>
                <CurrencyInput
                  className="form-control input-filter"
                  onValueChange={(v) =>
                    verifyFilters("value_goal_to", v ? v : "")
                  }
                  decimalSeparator=","
                  groupSeparator="."
                  datatype=""
                  placeholder="Até R$"
                />
              </Col>
            </Row>
          </Col>
          <Col lg="auto" className="mt-3">
            <label className="form-label">Data de resgate: De - Até</label>
            <Row>
              <Col>
                <div className="position-relative">
                  <input
                    type="date"
                    className="form-control input-filter"
                    onChange={(e) =>
                      verifyFilters("redemption_from", e.target.value)
                    }
                  />
                </div>
              </Col>
              <Col>
                <div className="position-relative">
                  <input
                    type="date"
                    className="form-control input-filter"
                    onChange={(e) =>
                      verifyFilters("redemption_to", e.target.value)
                    }
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg="auto" sm="6" className="mt-3">
            <label className="form-label">Descrição</label>
            <div className="position-relative">
              <input
                type="text"
                className="form-control input-filter"
                onChange={(e) => verifyFilters("description", e.target.value)}
              />
            </div>
          </Col>
          <Col lg="auto" sm="6" className="mt-3">
            <label className="form-label">Endereço</label>
            <div className="position-relative">
              <input
                type="text"
                className="form-control input-filter"
                onChange={(e) => verifyFilters("address", e.target.value)}
              />
            </div>
          </Col>
        </Row>
      </Card>
    </Collapse>
  );
};

export default Filter;
