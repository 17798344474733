import { AxiosResponse } from "axios";

import axiosInstance from "../../../utils/axios";
import { verifyData, verifyDataPagination, verifyResponse } from "../../../utils/verify-response";
import { UserForm, UserDetailList, UserDetail } from "../interfaces/user";

/*
interface PaginationResponse {
  list: Array<User>;
  total: number;
}*/

export async function getUserAll(filters?: any): Promise<UserDetailList> {
  return axiosInstance.get('/user/?' + new URLSearchParams(filters).toString()).then(response => {
    return verifyDataPagination(response);
  }).catch(error => {
    return verifyDataPagination(error);
  });
}

export async function getUser(uuid: string): Promise<UserDetail | undefined> {
  return axiosInstance.get('/user/' + uuid).then(response => {
    return verifyData(response);
  }).catch(error => {
    return verifyData(error);
  });
}

export async function postUser(userData: UserForm): Promise<AxiosResponse> {
  return axiosInstance.post('/user/', userData).then(response => {
    return verifyResponse(response);
  }).catch(error => {
    return verifyResponse(error);
  });
}

export async function updateUser(userData: any): Promise<AxiosResponse> {
  return axiosInstance.put('/user/', userData).then(response => {
    return verifyResponse(response);
  }).catch(error => {
    return verifyResponse(error);
  });
}

export async function deleteUser(uuid: string): Promise<AxiosResponse> {
  return axiosInstance.delete('/user/' + uuid).then(response => {
    return verifyResponse(response);
  }).catch(error => {
    return verifyResponse(error);
  });
}