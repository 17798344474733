import moment from "moment";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

import { itens_per_page, transaction_type } from "../../beans/enumerators";
import Avatar from "../../components/lists/Avatar";
import Header from "../../components/lists/Header";
import PaginationCustom from "../../components/lists/Pagination";
import { baseURL } from "../../utils/axios";
import { formatMoney } from "../../utils/format";
import useToken from "../../utils/hooks/useToken";
import Filter from "./components/Filter";
import TdSituation from "./components/TdSituation";
import WalletBalance from "./components/WalletBalance";
import { ListTransaction } from "./interfaces/transaction";
import { getAllTransactions } from "./services/List";

const UserInvestors = () => {
  const [nList, setNList] = useState<number>(0);
  const [transactionsList, setTransactionsList] = useState<
    ListTransaction["list"] | undefined
  >();
  const [pagination, setPagination] = useState<number>(0);
  const token = useToken();
  const [filters, setFilters] = useState<any>({
    user_name: "",
    data_period: "month",
    situation: ["0", "4", "5"],
    type: "",
    value_max: "",
    value_min: "",
    pagination: "1",
  });

  function verifyFilters(key: string, newValue: any) {
    let newFilters = filters;
    newFilters[key] = newValue;
    if (key !== "pagination") newFilters["pagination"] = "1";
    setFilters(newFilters);
    screenUpdate();
  }

  async function screenUpdate() {
    await getAllTransactions(filters).then((response) => {
      setTransactionsList(response.list);
      setNList(response.total);
      response.total > 0
        ? setPagination(Math.ceil(response.total / itens_per_page.default))
        : setPagination(0);
    });
  }

  useEffect(() => {
    screenUpdate();
  }, []);

  const [openFilters, setOpenFilters] = useState(false);

  return (
    <React.Fragment>
      <Helmet title="Extrato carteira" />
      <Container fluid className="p-0">
        <Header
          title="Extrato carteira"
          verifyFilters={verifyFilters}
          openFilters={openFilters}
          setOpenFilters={setOpenFilters}
        />
        <Filter
          openFilters={openFilters}
          verifyFilters={verifyFilters}
          theFilters={filters}
          token={token}
          transaction_type={undefined}
          extract={true}
        />

        <Row className="justify-content-between">
          <Col>
            <div className="mt-4">{nList} registros</div>
          </Col>
          <Col className="wallet-balance text-end">
            <WalletBalance />
          </Col>
        </Row>
        <Card className="flex-fill w-100 mt-3">
          <Table striped className="my-0">
            <thead>
              <tr key={"listwallet"}>
                {token.isAdmin && <th>Nome do usuário</th>}
                <th className={token.isAdmin ? "text-center" : ""}>
                  Tipo transação
                </th>
                <th className="text-center">Data</th>
                <th className="text-center">Valor</th>
                <th className="text-center">Situação</th>
                <th className="text-end">Saldo carteira</th>
              </tr>
            </thead>
            <tbody>
              {!!transactionsList &&
                transactionsList.map((item, key) => (
                  <>
                    <tr key={key}>
                      {token.isAdmin && (
                        <td>
                          <Avatar
                            src={
                              item.photo_image
                                ? baseURL + "/uploads/user/" + item.photo_image
                                : ""
                            }
                            name={item.user_name}
                            showName={true}
                          />
                        </td>
                      )}

                      <td className={token.isAdmin ? "text-center" : ""}>
                        {item.type === transaction_type.in
                          ? " Entrada"
                          : "Saída"}
                      </td>
                      <td className="text-center">
                        {moment(item.dt_created).format("DD/MM/YYYY")}
                      </td>
                      <td
                        className={`text-center ${item.type === transaction_type.in
                            ? "text-success"
                            : "text-danger"
                          } `}
                      >
                        {item.type === transaction_type.in ? "+" : "-"} R${" "}
                        {formatMoney(Number(item.value).toString())}
                      </td>
                      <TdSituation item={item} />
                      <td className="text-end">
                        R$ {formatMoney(Number(item.extract).toString())}
                      </td>
                    </tr>
                  </>
                ))}
            </tbody>
          </Table>
        </Card>
        <PaginationCustom
          pagination={pagination}
          filter={filters.pagination}
          verifyFilters={verifyFilters}
        />
      </Container>
    </React.Fragment>
  );
};

export default UserInvestors;
