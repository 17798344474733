import * as React from "react";
import { Navigate } from "react-router-dom";

import useToken from "../../utils/hooks/useToken";

interface GuestGuardType {
  children: React.ReactNode;
}

// For routes that can only be accessed by NOT authenticated users
function GuestGuard({ children }: GuestGuardType) {
  const { isValid } = useToken();

  if (isValid) {
    return <Navigate to="/" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default GuestGuard;
